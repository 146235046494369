import {createSlice} from '@reduxjs/toolkit';
import {TypesOrganizationInfoState} from "./organizationInfoSlice.types";

export const organizationInfoSlice = createSlice({
  name: 'organizationInfo',
  initialState: {
    name: null,
    id: null,
  } as TypesOrganizationInfoState,
  reducers: {
    setInfo: (state, action) => {
      state.id = action.payload.organizationId;
      state.name = action.payload.organizationName;
    },
  },
});

export const {setInfo} = organizationInfoSlice.actions;
export default organizationInfoSlice.reducer;