import {FormattedMessage, useIntl} from "react-intl";
import React, {useState} from "react";
import {TypesDashboardDemographics} from "./DashboardScoreAnalytics.types";
import {TARGETS} from "./DashboardScoreAnalytics.lib";
import useDashboardScoreAnalytics from "../../../hooks/use-dashboard-score-analytics/useDashboardScoreAnalytics";
import Placeholder from "../../../ui-components/Placeholder";
import SearchSelect from "../../../ui-components/SearchSelect";
import BoxPlotChart from "../../Charts/BoxPlotChart";
import translateChoice from "../../../utils/translateChoice";

const DashboardScoreAnalytics = ({ organization, dateFilters, answerFilters }: TypesDashboardDemographics) => {

  const intl = useIntl();

  // utilities
  const options = TARGETS.map((t) => ({
    label: intl.formatMessage({id: `demographics_${t}`}),
    value: t
  }));

  // state
  const [targets, setTargets] = useState(options.slice(0, 2));

  // hooks
  const data = useDashboardScoreAnalytics(
    organization,
    dateFilters,
    answerFilters,
    targets.map((t) => t.value),
  );
  const plotData = Object.entries(data ?? {}).reduce(
    (prev, [key, values]) => ({
      ...prev,
      [translateChoice(intl, "", key, true)]: values.map((v) => ({
        ...v,
        x: translateChoice(intl, "", v.x, true)
      }))
    }),
    {}
  );
  const isEmpty = Object.values(data ?? {}).every((seriesValues) => seriesValues.length === 0);

  return <>
    {/* ----- Target attribute selector ----- */}
    <div className="mb-3">
      <SearchSelect
        label={`${intl.formatMessage({id: 'select_variables'})} (max. 2)`}
        options={options}
        value={targets}
        onChange={(selected: {label: string, value: string}[]) => { setTargets(selected.slice(0, 2)); }}
        isMulti={true}
        customStyles={null}
        loadOptions={null}
        CustomOption={null}
      />
    </div>
    {/* ----- Plot ----- */}
    {data === null || data === undefined
      ? <Placeholder height="h-80" />
      : (
        isEmpty
          ? <div className="h-80 px-20 flex items-center justify-center">
            <span className="text-xl text-gray-400 font-bold">
              <FormattedMessage id="no_available_data_in_timespan" />
            </span>
          </div>
          : <BoxPlotChart
            id="score-analytics-plot"
            data={plotData}
            xAxisLabel={targets.slice(-1)?.[0]?.label}
            yAxisLabel={"Score"}
            precomputed={true}
          />
      )
    }

  </>
}

export default DashboardScoreAnalytics;