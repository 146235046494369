const en_questionnaire = {
  app_title: "App title",
  step_label_demographics: "Demographics",
  step_label_lifestyle: "Life style",
  step_label_medicalHistory: "Medical history",
  step_label_familyHistory: "Family history",
  step_label_general: "Generali",
  step_title_demographics: "Demographics",
  step_title_lifestyle: "Life style",
  step_title_medicalHistory: "Medical history",
  step_title_familyHistory: "Family history",
  step_title_general: "General",
  step_next_section_button: "Next",
  send_choices_button: "Send",
  question_text_patientsex: "What is the patient’s sex?",
  question_text_patientethnicity: "Is the patient Hispanic or Latino?",
  question_text_patientage: "What is the patient's age?",
  question_text_patientheight: "What is the patient's height?",
  question_text_patientweight: "What is the patient's weight?",
  choice_radio_male: "Male",
  choice_radio_female: "Female",
  choice_radio_unspecified: "I'd rather not answer",
  choice_radio_hispanic: "Hispanic",
  choice_radio_white: "Caucasian",
  choice_radio_black: "Black / African American",
  choice_radio_asian: "Asian American or Pacific Islander",
  choice_single_select_no_value: "Select a value",
  question_text_vegetablesservings: "In the last month, about how many servings of vegetables or leafy green salads did the patient eat per week?",
  choice_single_select_none: "None",
  choice_single_select_lessThanOneServingPerWeek: "Less than 1 serving/week",
  choice_single_select_betweenOneAndTwoServingsPerWeek: "1-2 servings/week",
  choice_single_select_betweenThreeAndFourServingsPerWeek: "3-4 servings/week",
  choice_single_select_betweenFiveAndSixServingsPerWeek: "5-6 servings/week",
  choice_single_select_betweenSevenAndTenServingsPerWeek: "7-10 servings/week",
  choice_single_select_moreThanTenServingsPerWeek: "More than 10 servings/week",
  question_text_moderateexercisemonths: "In the last year, in how many months did the patient do any kind of moderate physical activity?",
  question_text_vigorousexercisemonths: "In the last year, in how many months, if any, did the patient do any kind of vigorous activity?",
  question_text_cigaretteshundredormore: "Has the patient smoked 100 or more cigarettes in his lifetime?",
  choice_radio_yes: "Yes",
  choice_radio_no: "No",
  choice_radio_unknown: "Unknown",
  question_text_cigarettesperday: "When the patient was smoking regularly, how many cigarettes did he smoke per day?",
  choice_single_select_zeroCigarettesPerDay: "0 cigarettes a day",
  choice_single_select_oneToTenCigarettesPerDay: "1 to 10 cigarettes a day",
  choice_single_select_elevenToTwentyCigarettesPerDay: "11 to 20 cigarettes a day",
  choice_single_select_moreThanTwentyCigarettesPerDay: "More than 20 cigarettes a day",
  question_text_cigarettescurrentlysmoking: "Does the patient currently smoke cigarettes?",
  question_text_vegetablesquantityperserving: "In the last month, how much did the patient usually eat in each serving of vegetables or leafy green salads?",
  choice_single_select_lessThan100GramsPerServing: "100g or less",
  choice_single_select_between100And300GramsPerServing: "Between 100g - 300g",
  choice_single_select_between300And600GramsPerServing: "300g - 600g",
  choice_single_select_between600And1000GramsPerServing: "600g - 1kg",
  choice_single_select_moreThan1000GramsPerServing: "More than 1kg",
  question_text_vigorousexercisehoursperweek: "During those months, on average, about how many hours per week did the patient do vigorous physical activities?",
  choice_single_select_lessThanOneHourExercisePerWeek: "Up to 1 hour/week",
  choice_single_select_betweenOneAndTwoHoursPerWeek: "Between 1 - 2 hours/week",
  choice_single_select_betweenTwoAndThreeHoursPerWeek: "2 - 3 hours/week",
  choice_single_select_betweenThreeAndFourHoursPerWeek: "3 - 4 hours/week",
  choice_single_select_moreThanFourHoursPerWeek: "More than 4 hours/week",
  question_text_moderateexercisehoursperweek: "During those months, on average, about how many hours per week did the patient do moderate physical activities?",
  question_text_cigaretteswhenquitted: "How old was the patient when he quit smoking cigarettes completely? (If the patient quit more than once, use the most recent age he quit smoking completely.)",
  question_text_cigarettesstartage: "How old was the patient when he started smoking cigarettes on a regular basis (at least one cigarette a day for six months or longer)?",
  choice_single_select_neverSmokedRegularly: "Never smoked cigarettes regularly",
  question_text_colonscopylasttenyears: "During the past 10 years, did the patient have a colonoscopy, sigmoidoscopy, or both?",
  question_text_colonscopylasttenyearspolyps: "In the past 10 years, did a healthcare provider tell the patient that he or she had a colon or rectal polyp?",
  question_text_didtakensaid: "During the past 30 days, did the patient take medications containing aspirin at least 3 times a week, such as Bufferin, Bayer, Excedrin or other generic forms (do not include Tylenol)?",
  question_text_didtakenonnsaid: "During the past 30 days, did the patient take medications that do not contain aspirin at least 3 times a week, such as Advil, Aleve, Celebrex, Ibuprofen, Motrin, Naproxen or Nuprin (do not include Tylenol)?",
  question_text_stillhaveperiods: "Does the patient still have periods",
  question_text_whenlastperiod: "When did the patient have her last period",
  question_text_didtakeestrogen: "During the past two years, has the patient used estrogen, progestin, or other female hormones (these hormones may be given as hormone pills, oral contraceptives, shots, skin patches, vaginal creams, or as vaginal suppositories)?",
  choice_single_select_withinLastYear: "Within the last year",
  choice_single_select_betweenOneAndTwoYearsAgo: "Between 1 and 2 years ago",
  choice_single_select_moreThanTwoYearsAgo: "2 or more years ago",
  question_text_relativeswithcr: "Does the patient have any immediate relatives (mother, father, brothers or sisters, sons or daughters) who ever had cancer of the colon or rectum (cancer of the lower intestine)?",
  question_text_relativeswithcrcount: "How many of these relatives had cancer of the colon or rectum (cancer of the lower intestine)?",
  question_text_screeningprogram: "Are you already part of a Screening program?",
  question_text_bloodinfaeces: "Have you made a fecal occult blood test yet?",
  question_text_region: "In which region do you live?",
  question_text_province: "In which province do you live?",
  question_text_municipality: "In which municipality do you live?",
  question_text_postalcode: "What is your postal code?",
  choice_radio_one: "1",
  choice_radio_twoOrMore: "2 or more",
  choice_radio_unknow: "Doesn't know",
  "question_text_noscreeningbecauseof": "Fai già parte di un programma di screening?",
  "choice_radio_noScreeningBecauseAge": "Non ho l'età per farne parte",
  "choice_radio_noScreeningBecauseCommunication": " Non ho ricevuto nessuna comunicazione",
  "choice_radio_noScreeningBecauseWaiting": "Preferisco aspettare",
  "choice_radio_noScreeningBecauseMore": "Altre motivazioni",
  "question_text_nobloodinfeacesbecauseof": "Hai già fatto l'esame del sangue occulto nelle feci?",
  "choice_radio_noBloodInFeacesBecauseFear": "Pur avendo ricevuto comunicazione, preferisco aspettare perché mi fa paura l'esame",
  "choice_radio_nBloodInFeacesBecauseMore": "Altre motivazioni",
  "choice_radio_noBloodInFeacesBecauseDeliver": "Pur avendo ricevuto comunicazione, trovo difficile consegnare il campione biologico",
  "choice_radio_noBloodInFeacesBecauseReserve": "Pur avendo ricevuto comunicazione, trovo difficile prenotare l'esame",
  "choice_radio_noBloodInFeacesBecauseWaiting": "Pur avendo ricevuto comunicazione, preferisco aspettare",
  "choice_radio_noBloodInFeacesCommunication": "Pur avendo il range di età per far parte del programma di screening non ho ricevuto nessuna comunicazione",
  "question_text_bloodinfeacesresults": "Qual è stato l'esito dell'esame del sangue occulto nelle feci",
  "choice_radio_bloodInFeacesResultsNo": "No",
  "choice_radio_bloodInFeacesResultsYes": "Sì",
  "question_text_bloodinfeacescolonoscopy": "Ha in programma di fare una colonscopia in seguito al risultato dell'esame del sangue occulto nelle feci?",
  "choice_radio_bloodInFeacesColonoscopyYes": "Sì",
  "choice_radio_bloodInFeacesColonoscopyNo": "No",
  "question_text_bloodinfeacesnocolonoscopybecauseof": "Per quale motivo non ha in programma di fare una colonscopia?",
  "choice_radio_bloodInFeacesNoColonoscopyNoAvailability": "Non ho trovato disponibilità",
  "choice_radio_bloodInFeacesNoColonoscopyNoWait": "Preferisco aspettare",
  "choice_radio_bloodInFeacesNoColonoscopyNoDemanding": "Mi hanno che l'esame richiede una preparazione impegnativa",
  "choice_radio_bloodInFeacesNoColonoscopyNoMore": "Altre motivazioni",
  "question_text_bloodinfeacesyescolonoscopywait": "Indichi il tempo di attesa per la colonscopia",
  "choice_radio_colonoscopyWithin2Years": "Entro 2 anni",
  "choice_radio_colonoscopyWithin1Years": "Entro 1 anno",
  "choice_radio_colonoscopyWithin6Months": "Entro 6 mesi",
  "choice_radio_colonoscopyWithin3Months": "Entro 3 mesi",
  "choice_radio_colonoscopyWithin1Month": "Entro 1 mese",
  "choice_radio_colonoscopyNotYourBusiness": "Non voglio rispondere",
  "question_text_bloodinfeacesyesothertests": "Quali altri esami di approfondimento ha intenzione di fare?",
  "choice_radio_otherTestsNone": "Non ho in previsione nessun altro esame",
  "choice_radio_otherTestsHematological": "Esame ematologico",
  "choice_radio_otherTestsColonoscopy": "Colonscopia",
  "choice_radio_otherTestsEco": "Ecografia",
  "choice_radio_otherTestsMore": "Altro",
  error_get_result: "Error getting questionnaire results, please try later.",
  error_not_completed: "Complete the survey before send",
  results_score_label: "Current risk",
  results_level_label_low: "Low",
  results_level_label_medium: "Medium",
  results_level_label_high: "High",
  results_score_10_years_label: "Risk in 10 years",
  results_score_main_factors_label: "Most relevant factors in score calculation",
  results_main_factor_ethnicityFactor_title: "Ethnicity",
  results_main_factor_ethnicityFactor_text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ornare porttitor velit, non tincidunt ligula fermentum eu. Donec tempor a nulla nec dapibus.",
  results_main_factor_ageFactor_title: "Age",
  results_main_factor_ageFactor_text: "Nunc vel massa urna. Nulla facilisi. Nulla suscipit eros non elit vestibulum auctor. ",
  results_main_factor_bmiFactor_title: "Body mass index",
  results_main_factor_bmiFactor_text: "Donec at sollicitudin dui, a laoreet enim. Pellentesque id lectus placerat, placerat purus at, scelerisque leo. Integer congue, ligula lobortis efficitur maximus, urna diam rutrum mauris, ullamcorper tincidunt lacus mi at ligula.",
  results_main_factor_vegetablesFactor_title: "Diet",
  results_main_factor_vegetablesFactor_text: "Curabitur vehicula ut dui quis porta. Duis in accumsan lectus. Phasellus et varius augue, nec rhoncus neque.",
  results_main_factor_exerciseFactor_title: "Physical activity",
  results_main_factor_exerciseFactor_text: "In hac habitasse platea dictumst. Vestibulum id iaculis lacus. Donec ut vehicula turpis.",
  results_main_factor_cigarettesAgeFactor_title: "Sigarette",
  results_main_factor_cigarettesAgeFactor_text: "Fusce maximus, diam non aliquam volutpat, nunc nibh ultricies turpis, a aliquam nisi urna non massa.",
  results_main_factor_numberCigarettesFactor_title: "Cigarettes",
  results_main_factor_numberCigarettesFactor_text: "Phasellus pellentesque mauris feugiat velit dignissim feugiat. Aenean congue tempor elit, sed scelerisque massa tempor eget.",
  results_main_factor_colonscopyFactor_title: "Colonoscopy",
  results_main_factor_colonscopyFactor_text: "Etiam auctor, arcu ornare iaculis pellentesque, felis orci tincidunt elit, venenatis ultricies orci orci id urna. Aliquam ac elit enim.",
  results_main_factor_nsaidFactor_title: "NSAID",
  results_main_factor_nsaidFactor_text: "Nullam pellentesque massa nulla, at aliquam leo faucibus ut. ",
  results_main_factor_noIbuprofenFactor_title: "Ibuprofen",
  results_main_factor_noIbuprofenFactor_text: "Morbi fermentum metus eu justo consectetur, vitae convallis magna efficitur. Mauris sollicitudin odio et sapien consectetur, a accumsan nibh scelerisque.",
  results_main_factor_estrogenFactor_title: "Estrogen",
  results_main_factor_estrogenFactor_text: "Nunc volutpat ex eu eros luctus, id sodales risus volutpat. ",
  results_main_factor_familyHistoryFactor_title: "Family history",
  results_main_factor_familyHistoryFactor_text: "Morbi fringilla leo at odio efficitur, sit amet euismod orci viverra.",
  results_main_factor_patientSex_title: "Sex",
  results_main_factor_patientSex_text: "Donec et rutrum lectus. Nulla faucibus mi at velit gravida, et placerat nibh dapibus. Curabitur vitae dignissim risus. Donec aliquet sit amet eros vitae semper.",
  results_toolbar_divider_text: "or",
  results_toolbar_send_button_text: "Send results by email",
  results_toolbar_download_button_text: "Download PDF",
  results_no_data_to_show: "A problem has occurred. Please try again later.",
  questionnaire_step_loading: "Loading. Please wait...",
  results_more_info: "Ti invitiamo innanzi tutto a condividere i risultati con il tuo medico o specialista.",
  results_level_low_more_info: "Ti suggeriamo di fare l’esame del sangue occulto nelle feci",
  results_level_medium_more_info_part_1: "Ti suggeriamo di fare l’esame del sangue occulto nelle feci",
  results_level_medium_more_info_part_2: "o di effettuare una colonscopia",
  results_level_high_more_info: "Ti suggeriamo di effettuare una colonscopia",
  results_more_info_text: "se vuoi saperne di più clicca",
  results_more_info_here: "qui",
  results_level_low_more_info_details: "Informazioni aggiuntive rischio basso",
  results_level_medium_more_info_details_A: "Informazioni aggiuntive rischio medio",
  results_level_medium_more_info_details_B: "Informazioni aggiuntive rischio medio",
  results_level_high_more_info_details: "Informazioni aggiuntive rischio alto",
};

export default en_questionnaire;

