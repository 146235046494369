import React, {useState} from "react";
import {TypesS3ImageProps} from "./S3Image.types";
import Placeholder from "../../../ui-components/Placeholder";
import Modal from "../../../ui-components/Modal";
import useS3File from "../../../hooks/use-s3-file/useS3File";
import { PhotoIcon } from "@heroicons/react/24/solid";

const S3Image = ({src, alt, clickable=false, extraClass="w-full", extraStyle={}}: TypesS3ImageProps) => {

  const [preview, setPreview] = useState<boolean>(false);
  const preSignedUrl = useS3File(src);

  // utilities
  return <>
    <div className={extraClass} style={extraStyle}>
      {
        src
          ? (
            preSignedUrl
              ? (
                <div
                  className={`w-full h-full bg-center bg-no-repeat bg-cover rounded ${clickable ? 'cursor-pointer' : ''}`}
                  style={{backgroundImage: `url(${preSignedUrl})`}}
                  onClick={() => clickable && preSignedUrl && setPreview(true)}
                >
                  <img
                    src={preSignedUrl}
                    alt={alt ?? ""}
                    className={"w-full h-full opacity-0"}
                  />
                </div>
              )
              : <Placeholder classNames="w-full h-full" marginCls="" roundedCls="rounded" />
          )
          : (
            <div className="flex items-center justify-center bg-gray-200 rounded-md cursor-not-allowed w-full h-full">
              <PhotoIcon className="w-7 text-gray-400" />
            </div>
          )
      }
    </div>
    {
      preview && preSignedUrl && (
        // @ts-ignore
        <Modal onExit={() => setPreview(false)} opened={true}>
          <img src={preSignedUrl} alt={alt ?? ""} className="w-full h-auto" />
        </Modal>
      )
    }
  </>
}

export default S3Image;