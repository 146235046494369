import {createSlice} from '@reduxjs/toolkit';
import {TypesQuestionnaireResultsState} from "./questionnaireResults.types";

export const questionnaireResultsSlice = createSlice({
  name: 'questionnaireResults',
  initialState: {
    results: null,
  } as TypesQuestionnaireResultsState,
  reducers: {
    addResults: (state, action) => {
      state.results = action.payload.results;
    },
  },
});

export const {addResults} = questionnaireResultsSlice.actions;
export default questionnaireResultsSlice.reducer;