import {Typethresholds} from "../../../redux/features/questionnaireResults/questionnaireResults.types";

/**
 * @function getScorePosition
 *
 * @param {number | null} score Current score
 * @param {Typethresholds} thresholds Thresholds about score
 * @return {number} Position on bar score
 */
export const getScorePosition = (score: number | null, thresholds: Typethresholds): number => {

  if (!score || !thresholds.low || !thresholds.high || !thresholds.max) {
    return 0;
  }

  switch (true) {
    case (score <= thresholds.low):
      return (score / thresholds.low * 33.33);
    case (score > thresholds.low && score <= thresholds.high):
      return (score / thresholds.high * 33.33) + 33.33;
    case (score > thresholds.high):
      return (score / thresholds.max * 33.33) + 66.66;
    default:
      return 0;
  }
};