import Button from "../../../ui-components/Button";
import {FormattedMessage, useIntl} from "react-intl";
import React, {useState} from "react";
import IntroHeader from "../intro-header/IntroHeader";
import {TypesIntroTermsAndConditionsProps} from "./IntroTermsAndConditions.types";
import Checkbox from "../../../ui-components/Checkbox";
import S3Document from "../../S3Document/s3-document/S3Document";
import {ArrowDownTrayIcon} from "@heroicons/react/24/outline";

const IntroTermsAndConditions = ({setStage, organizationData}: TypesIntroTermsAndConditionsProps) => {
  const [accepted, setAccepted] = useState(false);
  const intl = useIntl();

  const renderAcceptBtn = (disabled: boolean) => (
    // @ts-ignore
    <Button
      styleType="blue"
      paddingCls="py-3 px-8"
      className="m-auto"
      textSizeCls="text-lg"
      submitting={disabled}
    >
      <ArrowDownTrayIcon className="w-5 -ml-1 mr-3" />
      <span className="uppercase font-semibold">
        <FormattedMessage id="intro_app_terms_and_conditions_download_button" />
      </span>
    </Button>
  );

  return <div className="introtermsandconditions__container py-4 px-8 max-w-screen-xl m-auto">
    <IntroHeader organizationData={organizationData}/>
    <div className="introtermsandconditions__body">
      <div className="introtermsandconditions__title pt-12 text-center text-2xl font-extrabold">
        <FormattedMessage id="intro_app_terms_and_conditions_title"/>
      </div>

      {!organizationData.val_tec_url
        ? <div className="w-full h-40 flex items-center justify-center md:text-xl text-red-700 text-center">
          <FormattedMessage id="missing_tec_document" />
        </div>
        : <>
          <div className="introtermsandconditions__download-button-container pt-9 flex flex-col justify-center items-center">
            <S3Document
              url={organizationData.val_tec_url || ""}
              placeholder={renderAcceptBtn(true)}
            >
              {renderAcceptBtn(false)}
            </S3Document>
            <div
              className="introtermsandconditions__checkbox pt-6 m-auto flex gap-2">
              <div className="introtermsandconditions__checkbox-element">
                <Checkbox
                  readOnly={true}
                  id="terms_and_conditions"
                  name="terms_and_conditions"
                  checked={accepted}
                  classNames="cursor-pointer"
                  onChange={() => {
                    setAccepted(!accepted);
                  }}
                />
              </div>
              <div
                className="introtermsandconditions__checkbox-text cursor-pointer"
                onClick={() => {
                  setAccepted(!accepted);
                }}
              >
                <FormattedMessage id="intro_app_terms_and_conditions_checkbox_text"/>
              </div>
            </div>
          </div>
          <div className="introtermsandconditions__toolbar flex justify-center pt-10">
            <Button
              full={false}
              type="button"
              children={intl.formatMessage({id: "intro_app_terms_and_conditions_button"})}
              onClick={() => {
                setStage(2);
              }}
              disabled={!accepted}
              className="text-lg"
            />
          </div>
        </>
      }

    </div>
  </div>;
};

export default IntroTermsAndConditions;