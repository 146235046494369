import React, {useCallback} from "react";
import {TypesQuestionnaireStepsProps} from "./QuestionnaireSteps.types";
import {TypesQuestionnaireSection} from "../../../hooks/use-questionnaire-data/useQuestionnaireData.type";
import {FormattedMessage} from "react-intl";

const QuestionnaireSteps = ({
                              questionnaireData,
                              current,
                              setCurrent,
                              filled
                            }: TypesQuestionnaireStepsProps) => {

  /**
   * @function getTabIcon
   *
   * @param {number | string} id Id of the icon to return
   * @return {JSX.Element} Icon code by id
   */
  const getTabIcon = (id: number | string) => {
    switch (id) {
      case 1:
        return <span
          className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-yellow-300">
          <span className="material-symbols-outlined">demography</span>
        </span>;
      case 2:
        return <span
          className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-yellow-300">
          <span className="material-symbols-outlined">emoji_people</span>
        </span>;
      case 3:
        return <span
          className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-yellow-300">
          <span className="material-symbols-outlined">folder_open</span>
        </span>;
      case 4:
        return <span
          className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-yellow-300 ">
          <span className="material-symbols-outlined">family_restroom</span>
        </span>;
      case 5:
        return <span
          className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-yellow-300 ">
          <span className="material-symbols-outlined">note_alt</span>
        </span>;
      case "completed":
        return <span
          className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 bg-yellow-300 border-yellow-300">
          <span className="material-symbols-outlined ">check</span>
        </span>;
    }
  };

  /**
   * @function getSectionTab
   *
   * @param {TypesQuestionnaireSection} section Current section data
   * @return {JSX.Element} Tab code by section data
   */
  const getSectionTab = useCallback(
    (section: TypesQuestionnaireSection) => {
      return <button
        className="group flex w-full items-center"
        onClick={() => {
          setCurrent(section.id);
          window.scrollTo(0, 0);
        }}
      >
        <span className="flex items-center px-6 py-4 text-sm font-medium">
          {getTabIcon(filled.includes(section.id) ? "completed" : section.id)}
          {section.id === current ?
            <span className="ml-4 text-sm font-medium text-black-900 font-bold"><FormattedMessage
              id={"step_label_" + section.des_locale_key}/></span> :
            <span className="ml-4 text-sm font-medium text-gray-400"><FormattedMessage
              id={"step_label_" + section.des_locale_key}/></span>}
        </span>
      </button>;
    },
    [current, filled],
  );

  return (
    <nav className="questionnairesteps__container mt-8" aria-label="Progress">
      <ol className="divide-y divide-gray-300 bg-white rounded-md md:flex md:divide-y-0">
        {questionnaireData.sections.map((section: TypesQuestionnaireSection, index: number) => (
          <li key={section.id} className="relative md:flex md:flex-1">
            {getSectionTab(section)}
            {index !== questionnaireData.sections.length - 1 && (
              <div className="absolute top-0 right-0 hidden h-full w-5 md:block" aria-hidden="true">
                <svg
                  className="h-full w-full text-gray-300"
                  viewBox="0 0 22 80"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0 -2L20 40L0 82"
                    vectorEffect="non-scaling-stroke"
                    stroke="currentcolor"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>)}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default QuestionnaireSteps;