import React from "react";
import {IntlShape, FormattedMessage} from "react-intl";
import {TypesOrganizationData} from "../../../hooks/use-organization-data/useOrganizationData.types";
import {Column, EditComponentProps, Options} from "@material-table/core";
import S3Image from "../../S3Image/s3-image/S3Image";
import S3FileUploader from "../../S3Image/s3-image-uploader/S3FileUploader";
import {DocumentTextIcon, ExclamationCircleIcon} from "@heroicons/react/24/outline";
import S3Document from "../../S3Document/s3-document/S3Document";

export const getColumns = (intl: IntlShape) => [
  {
    // title: intl.formatMessage({ id: "image" }),
    field: "val_image_url",
    render: (o: TypesOrganizationData) =>
      <S3Image src={o.val_image_url} alt={o.des_name} clickable={true} extraClass="w-16" extraStyle={{aspectRatio: "1"}} />, 
    editComponent: (props: EditComponentProps<TypesOrganizationData>) =>
      <S3FileUploader
        onChange={props.onChange}
        bucketPath="images/organizations"
        accept="image/png, image/jpeg"
      >
        <S3Image
          src={props.value}
          clickable={false}
          extraClass="w-16"
          extraStyle={{aspectRatio: "1"}}
        />
      </S3FileUploader>,
    filtering: false,
    width: 0
  },
  {
    title: intl.formatMessage({ id: "name" }),
    field: "des_name",
    cellStyle: {whiteSpace: "nowrap", minWidth: "200px"}
  },
  {
    title: <span className="whitespace-nowrap">
      {intl.formatMessage({id: "contract_expiration_date"})}
    </span>,
    field: "tms_contract_expiration",
    type: "date",
    render: (o: TypesOrganizationData) => {
      if (!o.tms_contract_expiration)
        return <FormattedMessage key="noneF" />
      const parsedDate = new Date(Date.parse(o.tms_contract_expiration as unknown as string));
      return <span className={parsedDate < new Date() ? 'text-red-700' : 'text-black'}>
        {parsedDate.toLocaleDateString()}
      </span>
    },
  },
  {
    title: intl.formatMessage({ id: "users" }),
    field: "users_count",
    type: "numeric",
    editable: "never",
    cellStyle: {textAlign: "left"}
  },
  {
    title: intl.formatMessage({ id: "disabled" }),
    field: "flg_disabled",
    type: "boolean",
  },
  {
    title: <span className="whitespace-nowrap">
      <FormattedMessage id="terms_and_conditions" />
    </span>,
    field: "val_tec_url",
    render: ({val_tec_url}: TypesOrganizationData) => !!val_tec_url
      ? <S3Document url={val_tec_url} placeholder={null}>
        <div className="flex gap-3 items-center">
          <DocumentTextIcon className="w-6" />
          <FormattedMessage id="download_file" defaultMessage="Scarica file" />
        </div>
      </S3Document>
      : <span className="flex items-center gap-2 text-red-600 whitespace-nowrap">
        <ExclamationCircleIcon className="w-6" />
        <FormattedMessage id="no_document" />
      </span>,
    editComponent: (props: EditComponentProps<TypesOrganizationData>) =>
      <S3FileUploader
        onChange={props.onChange}
        bucketPath="tec/organizations"
        accept="application/pdf"
      >
        <div className="flex flex-col gap-1.5 items-center justify-center bg-gray-200 text-xs text-gray-500 px-2 py-3 rounded">
          {!!props.value
            ? <S3Document url={props.value} placeholder={null}>
                <div className="flex flex-col gap-1 p-1 items-center">
                  <DocumentTextIcon className="w-8" />
                  <FormattedMessage id="download_file" defaultMessage="Scarica file" />
                </div>
              </S3Document>
            : <span><FormattedMessage id="no_document" /></span>
          }
        </div>
      </S3FileUploader>,
    filtering: false,
    width: 0
  },
] as Column<TypesOrganizationData>[];

export const OPTIONS: Options<TypesOrganizationData> = {
  filtering: false,
  pageSize: 5,
  searchFieldAlignment: "left",
  searchFieldStyle: {
    marginTop: "2px",
  },
  showTitle: false,
  toolbar: false,
};

export const getLocalization = (intl: IntlShape) => ({
  header: {
    actions: "",
  },
  body: {
    editRow: {
      deleteText: intl.formatMessage({ id: "delete_organization" }),
    },
  },
  pagination: {
    labelDisplayedRows: intl.formatMessage({ id: "organizations_displayed_rows" }),
    labelRowsSelect: intl.formatMessage({ id: "organizations"}),
  },
});
