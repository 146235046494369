import React from "react";

const Placeholder = ({
    classNames = "",
    width = "w-full",
    height = "h-5",
    marginCls = "mb-2",
    roundedCls = "rounded-3xl",
    style = {}
}) => {
  return (
    <div
      data-placeholder
      className={`overflow-hidden relative bg-gray-200 ${height} ${width} ${marginCls} ${roundedCls} ${classNames}`}
      style={style}
    />
  );
};

export default Placeholder;
