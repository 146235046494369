import React, {useEffect, useState} from "react";
import "./QuestionnaireScoreChart.scss";
import {FormattedMessage} from "react-intl";
import {TypesQuestionnaireScoreChartProps} from "./QuestionnaireScoreChart.types";
import {getScorePosition} from "./QuestionnaireScore.lib";


const QuestionnaireScoreChart = ({score, thresholds}: TypesQuestionnaireScoreChartProps) => {
  const [position, setPosition] = useState<number>(0);

  useEffect(() => {
    if (score && thresholds) {
      setPosition(getScorePosition(score, thresholds));
    }
  }, [score, thresholds]);

  return <div className="questionnairescorechart__container w-60">
    <div className="questionnairescorechart__arrow-container"
         style={{marginLeft: "calc(" + position + "% - 15px)"}}>
      <span className="material-symbols-outlined">arrow_downward</span>
    </div>
    <div className="questionnairescorechart__bar-container w-60">
      <div className="questionnairescorechart__level questionnairescorechart__level--low">
        <FormattedMessage id="results_level_label_low"/>
      </div>
      <div className="questionnairescorechart__level questionnairescorechart__level--medium">
        <FormattedMessage id="results_level_label_medium"/>
      </div>
      <div className="questionnairescorechart__level questionnairescorechart__level--higth">
        <FormattedMessage id="results_level_label_high"/>
      </div>
    </div>
  </div>;
};

export default QuestionnaireScoreChart;