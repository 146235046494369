import React, { useCallback } from "react";
import LogoAmmagamma from "./LogoAmmagamma";
import LogoOlympus from "./LogoOlympus";
import { useAppSelector } from "../../redux/hooks";

const PoweredBy = () => {
  const organizationName = useAppSelector((state) => state.organizationInfo.name);
  const getOrganizationLogo = useCallback(
    () => (!organizationName || (organizationName && organizationName.match(/olympus/i) === null)) && <a
      href="https://www.olympus.it"
      target="_blank"
      rel="noopener noreferrer"
    >
      <LogoOlympus height="h-5"/>
    </a>,
    [organizationName],
  );

  return (
    <div className="flex flex-col items-center">
      <p className="whitespace-nowrap my-3 text-sm">Powered by</p>
      <div className="flex items-center justify-center gap-4">
        {getOrganizationLogo()}
        <a
          href="https://www.ammagamma.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LogoAmmagamma height="16" />
        </a>
      </div>
    </div>
  );
};

export default PoweredBy;
