import React, {FormEvent, useContext, useState} from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {TypesOrganizationBaseData} from "../../../hooks/use-organization-data/useOrganizationData.types";
import { NotificationsContext } from "../../../ui-components/Notifications";
import Modal from "../../../ui-components/Modal";
import {useOrganizationDispatcher} from "../../../hooks/use-organization-dispatcher/useOrganizationDispatcher";
import {TypesOrganizationAddDialogProps} from "./OrganizationsAddDialog.types";
import Button from "../../../ui-components/Button";
import Toggle from "../../../ui-components/Toggle";
import Input from "../../../ui-components/Input";
import S3FileUploader from "../../S3Image/s3-image-uploader/S3FileUploader";
import Label from "../../../ui-components/Label";
import {CALENDAR_MODE} from "../../../utils/constants";
import DatePicker from "../../../ui-components/DatePicker";
import S3Image from "../../S3Image/s3-image/S3Image";
import S3Document from "../../S3Document/s3-document/S3Document";
import {DocumentTextIcon, ArrowUpTrayIcon} from "@heroicons/react/24/outline";


// Constants
const NEW_ORGANIZATION_DEFAULT_EXPIRATION_YEARS = 2;


function OrganizationsAddDialog({ onClose }: TypesOrganizationAddDialogProps) {

  const intl = useIntl();
  const { push } = useContext(NotificationsContext);
  const dispatch = useOrganizationDispatcher();

  // utilities
  const getNYearsFromNow = (n: number) => new Date(new Date().setFullYear(new Date().getFullYear() + n));

  // state
  const [organization, setOrganization] = useState<TypesOrganizationBaseData>({
    des_name: "",
    val_image_url: null,
    val_tec_url: null,
    tms_contract_expiration: getNYearsFromNow(NEW_ORGANIZATION_DEFAULT_EXPIRATION_YEARS),
    flg_disabled: false
  });
  const [uploading, setUploading] = useState<boolean>(false);

  const _onSubmit = async (e: FormEvent<HTMLFormElement>) => {

    e.preventDefault();

    setUploading(true);
    try {
      await dispatch(organization);
      // add dispatched organization in local list
      push({
        type: "success",
        title: intl.formatMessage({ id: "organization_created_successfully" }),
      });
      onClose();
    }
    catch (err) {
      push({
        title: intl.formatMessage({ id: "server_error" }),
        type: "error",
      });
      throw err;
    }
    finally {
      setUploading(false);
    }
  };

  return (
    // @ts-ignore
    <Modal onExit={onClose} opened={true}>
      <div className="m-8">
        <h3 className="text-center text-2xl font-extrabold text-gray-900">
          <FormattedMessage id="add_organization" />
        </h3>
      </div>

      <form id="add-user-form" onSubmit={(e) => _onSubmit(e)}>

        {/*@ts-ignore*/}
        <Input
          type="text"
          value={organization.des_name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setOrganization((prev) => ({...prev, des_name: e.target?.value}));
          }}
          label={intl.formatMessage({id: "name"})}
          errorMessage={intl.formatMessage({id: "fieldRequiredError"})}
          required
        />

        <Label>{intl.formatMessage({id: 'image'})}</Label>
        <div className="mt-1 mb-4">
          <S3FileUploader
            onChange={(url: string) => {
              setOrganization((prev) => ({...prev, val_image_url: url})); }
            }
            bucketPath="images/organizations/"
            accept="image/png, image/jpeg"
          >
            <S3Image
              src={organization.val_image_url}
              clickable={false}
              extraClass="w-16"
              extraStyle={{aspectRatio: "1"}}
            />
          </S3FileUploader>
        </div>

        <Label><FormattedMessage id="terms_and_conditions" /></Label>
        <div className="mt-1 mb-4">
          <S3FileUploader
            onChange={(url: string) => {
              setOrganization((prev) => ({...prev, val_tec_url: url})); }
            }
            bucketPath="tec/organizations/"
            accept="application/pdf"
          >
            <div className="flex flex-col gap-1.5 items-center justify-center bg-gray-200 w-16 h-16 text-xs text-gray-500 px-2 py-1.5 rounded-lg" style={{fontSize: '0.65rem'}}>
              {!!organization.val_tec_url
                ? <S3Document url={organization.val_tec_url} placeholder={null}>
                    <div className="flex flex-col gap-1 p-1 items-center">
                      <DocumentTextIcon className="w-6" />
                      <FormattedMessage id="download" defaultMessage="Scarica" />
                    </div>
                  </S3Document>
                : <span><ArrowUpTrayIcon className="w-6" /></span>
              }
            </div>
          </S3FileUploader>
        </div>

        <DatePicker
          value={organization.tms_contract_expiration}
          onChange={(selected: Date) => {
            setOrganization((prev) => ({...prev, tms_contract_expiration: selected}));
          }}
          label={intl.formatMessage({id: 'contract_expiration_date'})}
          errorMessage={intl.formatMessage({id: "fieldRequiredError"})}
          mode={CALENDAR_MODE.DAILY}
          minDate={undefined}
          maxDate={undefined}
        />

        <Toggle
          vertical
          label={intl.formatMessage({id: "disabled"})}
          description={null}
          checked={organization.flg_disabled}
          onChange={() => setOrganization(
            (prev) => ({...prev, flg_disabled: !prev.flg_disabled})
          )}
        />

        <div className="flex justify-end mt-4">
          <Button type="submit" full={false} submitting={uploading}>
            <FormattedMessage id="add" />
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default OrganizationsAddDialog;
