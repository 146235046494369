import React from "react";

const LogoOlympus = ({ height = "h-8", width = "auto" }) => (
  <img
    className={`${height} ${width}`}
    src={process.env.PUBLIC_URL + "/assets/olympus-logo-color.png"}
    alt="olympus logo"
  />
);

export default LogoOlympus;
