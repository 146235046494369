const it_questionnaire = {
  app_title: "CRC score",
  step_label_demographics: "Demografia",
  step_label_lifestyle: "Stile di vita",
  step_label_medicalHistory: "Storia clinica",
  step_label_familyHistory: "Storia familiare",
  step_label_general: "Generali",
  step_title_demographics: "Demografia",
  step_title_lifestyle: "Stile di vita",
  step_title_medicalHistory: "Storia clinica",
  step_title_familyHistory: "Storia familiare",
  step_title_general: "Generali",
  step_next_section_button: "Prosegui",
  send_choices_button: "Invia",
  question_text_patientsex: "Qual è il tuo sesso?",
  question_text_patientethnicity: "Qual è la tua etnia?",
  question_text_patientage: "Seleziona la tua età",
  question_text_patientheight: "Inserisci la tua altezza",
  question_text_patientweight: "Inserisci il tuo peso",
  choice_radio_male: "Maschile",
  choice_radio_female: "Femminile",
  choice_radio_unspecified: "Preferisco non rispondere",
  choice_radio_hispanic: "Ispanica/Latino americana",
  choice_radio_white: "Bianca Caucasica",
  choice_radio_black: "Nera",
  choice_radio_asian: "Asiatica",
  choice_single_select_no_value: "Seleziona",
  question_text_vegetablesservings: "Nell'ultimo mese, quante porzioni di verdura o insalate verdi hai mangiato a settimana?",
  choice_single_select_none: "Nessuna",
  choice_single_select_lessThanOneServingPerWeek: "Meno di 1 porzione/settimana",
  choice_single_select_betweenOneAndTwoServingsPerWeek: "1-2 porzioni/settimana",
  choice_single_select_betweenThreeAndFourServingsPerWeek: "3-4 porzioni/settimana",
  choice_single_select_betweenFiveAndSixServingsPerWeek: "5-6 porzioni/settimana",
  choice_single_select_betweenSevenAndTenServingsPerWeek: "7-10 porzioni/settimana",
  choice_single_select_moreThanTenServingsPerWeek: "Più di 10 porzioni/settimana",
  question_text_moderateexercisemonths: "Nell'ultimo anno, per quanti mesi hai svolto attività fisica moderata (si intende attività senza elevato consumo di energia come fare una passeggiata)?",
  question_text_vigorousexercisemonths: "Nell'ultimo anno, se applicabile, per quanti mesi hai svolto qualsiasi tipo di attività intensa (si intende attività con elevato consumo di energia, come corsa, ciclismo o altre attività ad alto sforzo)?",
  question_text_cigaretteshundredormore: "Hai fumato 100 o più sigarette nella tua vita?",
  choice_radio_yes: "Sì",
  choice_radio_no: "No",
  choice_radio_unknown: "Non lo so",
  question_text_cigarettesperday: "Quando fumavi regolarmente, quante sigarette fumavi al giorno?",
  choice_single_select_zeroCigarettesPerDay: "0 sigarette al giorno",
  choice_single_select_oneToTenCigarettesPerDay: "Da 1 a 10 sigarette al giorno",
  choice_single_select_elevenToTwentyCigarettesPerDay: "Da 11 a 20 sigarette al giorno",
  choice_single_select_moreThanTwentyCigarettesPerDay: "Più di 20 sigarette al giorno",
  question_text_cigarettescurrentlysmoking: "Attualmente fumi sigarette?",
  question_text_vegetablesquantityperserving: "Nell'ultimo mese, quanto hai mangiato solitamente per ogni porzione di verdura o insalata verde?",
  choice_single_select_lessThan100GramsPerServing: "100g o meno",
  choice_single_select_between100And300GramsPerServing: "Da 100g a 300g",
  choice_single_select_between300And600GramsPerServing: "Da 300g a 600g",
  choice_single_select_between600And1000GramsPerServing: "Da 600g a 1kg",
  choice_single_select_moreThan1000GramsPerServing: "Più di 1kg",
  question_text_vigorousexercisehoursperweek: "Durante quei mesi, in media, quante ore alla settimana hai svolto di attività fisica intensa (si intende attività con elevato consumo di energia, come corsa, ciclismo o altre attività ad alto sforzo)?",
  choice_single_select_lessThanOneHourExercisePerWeek: "Fino a 1 ora/settimana",
  choice_single_select_betweenOneAndTwoHoursPerWeek: "Tra 1 e 2 ore/settimana",
  choice_single_select_betweenTwoAndThreeHoursPerWeek: "2 - 3 ore/settimana",
  choice_single_select_betweenThreeAndFourHoursPerWeek: "3 - 4 ore/settimana",
  choice_single_select_moreThanFourHoursPerWeek: "Più di 4 ore/settimana",
  question_text_moderateexercisehoursperweek: "Durante quei mesi, in media, quante ore settimanali hai svolto di attività fisica moderata (si intende attività senza elevato consumo di energia come fare una passeggiata)?",
  question_text_cigaretteswhenquitted: "Quanti anni avevi quando hai smesso completamente di fumare sigarette? (Se hai smesso più di una volta, indica l'età più recente in cui hai smesso completamente di fumare.)",
  question_text_cigarettesstartage: "Quanti anni avevi quando hai iniziato a fumare regolarmente sigarette (almeno una sigaretta al giorno per sei mesi o più)?",
  choice_single_select_neverSmokedRegularly: "Non ho mai fumato sigarette regolarmente",
  question_text_colonscopylasttenyears: "Negli ultimi 10 anni, sei stato sottoposto a colonscopia, sigmoidoscopia o entrambe?",
  question_text_colonscopylasttenyearspolyps: "Negli ultimi dieci anni, ti è stato comunicato di avere un polipo del colon o del retto de un operatore sanitario?",
  question_text_didtakensaid: "Negli ultimi 30 giorni, hai assunto farmaci contenenti aspirina almeno 3 volte a settimana?",
  question_text_didtakenonnsaid: "Negli ultimi 30 giorni, hai assunto farmaci antinfiammatori non steroidei (FANS/COX-2) che NON contengono aspirina almeno 3 volte a settimana?",
  question_text_stillhaveperiods: "Hai ancora il ciclo mestruale?",
  question_text_whenlastperiod: "Quando hai avuto il tuo ultimo ciclo mestruale?",
  question_text_didtakeestrogen: "Negli ultimi due anni, hai usato estrogeni, progestinici o altri ormoni femminili (questi ormoni possono essere somministrati come pillole ormonali, contraccettivi orali, iniezioni, cerotti cutanei, creme vaginali o come supposte vaginali)?",
  choice_single_select_withinLastYear: "Nell'ultimo anno",
  choice_single_select_betweenOneAndTwoYearsAgo: "Tra 1 e 2 anni fa",
  choice_single_select_moreThanTwoYearsAgo: "2 o più anni fa",
  question_text_relativeswithcr: "Hai parenti stretti (madre, padre, fratelli o sorelle, figli o figlie) che hanno mai avuto un cancro del colon o del retto (cancro dell'intestino inferiore)?",
  question_text_relativeswithcrcount: "Quanti di questi parenti avevano il cancro del colon o del retto (cancro dell'intestino inferiore)?",
  question_text_screeningprogram: "Fai già parte di un programma di screening per la prevenzione del tumore del colon-retto?",
  question_text_bloodinfaeces: "Hai già fatto l'esame del sangue occulto nelle feci?",
  question_text_region: "In quale regione abiti?",
  question_text_province: "In quale provincia abiti?",
  question_text_municipality: "In quale comune abiti?",
  question_text_postalcode: "Qual è il tuo cap?",
  choice_radio_one: "1",
  choice_radio_twoOrMore: "2 o più",
  choice_radio_unknow: "Non lo so",
  question_text_noscreeningbecauseof: "Per quale motivo non fai parte di un programma di screening?",
  choice_radio_noScreeningBecauseAge: "Non ho l'età per farne parte",
  choice_radio_noScreeningBecauseCommunication: " Non ho ricevuto nessuna comunicazione",
  choice_radio_noScreeningBecauseWaiting: "Preferisco aspettare",
  choice_radio_noScreeningBecauseMore: "Altre motivazioni",
  question_text_nobloodinfeacesbecauseof: "Per quale motivo non hai ancora fatto l'esame del sangue occulto nelle feci?",
  choice_radio_noBloodInFeacesBecauseFear: "Pur avendo ricevuto comunicazione, preferisco aspettare perché mi fa paura l'esame",
  choice_radio_nBloodInFeacesBecauseMore: "Altre motivazioni",
  choice_radio_noBloodInFeacesBecauseDeliver: "Pur avendo ricevuto comunicazione, trovo difficile consegnare il campione biologico",
  choice_radio_noBloodInFeacesBecauseReserve: "Pur avendo ricevuto comunicazione, trovo difficile prenotare l'esame",
  choice_radio_noBloodInFeacesBecauseWaiting: "Pur avendo ricevuto comunicazione, preferisco aspettare",
  choice_radio_noBloodInFeacesCommunication: "Pur avendo il range di età per far parte del programma di screening non ho ricevuto nessuna comunicazione",
  question_text_bloodinfeacesresults: "Qual è stato l'esito dell'esame del sangue occulto nelle feci?",
  choice_radio_bloodInFeacesResultsNo: "Negativo",
  choice_radio_bloodInFeacesResultsYes: "Positivo",
  question_text_bloodinfeacescolonoscopy: "Hai in programma di fare una colonscopia in seguito al risultato dell'esame del sangue occulto nelle feci?",
  choice_radio_bloodInFeacesColonoscopyYes: "Sì",
  choice_radio_bloodInFeacesColonoscopyNo: "No",
  question_text_bloodinfeacesnocolonoscopybecauseof: "Per quale motivo non hai in programma di fare una colonscopia?",
  choice_radio_bloodInFeacesNoColonoscopyNoAvailability: "Non ho trovato disponibilità in ospedale",
  choice_radio_bloodInFeacesNoColonoscopyNoWait: "Preferisco aspettare",
  choice_radio_bloodInFeacesNoColonoscopyNoDemanding: "Mi hanno detto che l'esame richiede una preparazione impegnativa",
  choice_radio_bloodInFeacesNoColonoscopyNoFear: "Mi fa paura l'esame",
  choice_radio_bloodInFeacesNoColonoscopyNoMore: "Altre motivazioni",
  question_text_bloodinfeacesyescolonoscopywait: "Indica il tempo di attesa per la colonscopia",
  choice_single_select_colonoscopyWithin2Years: "Entro 2 anni",
  choice_single_select_colonoscopyWithin1Years: "Entro 1 anno",
  choice_single_select_colonoscopyWithin6Months: "Entro 6 mesi",
  choice_single_select_colonoscopyWithin3Months: "Entro 3 mesi",
  choice_single_select_colonoscopyWithin1Month: "Entro 1 mese",
  choice_single_select_colonoscopyNotYourBusiness: "Non voglio rispondere",
  question_text_bloodinfeacesyesothertests: "Quali altri esami di approfondimento ha intenzione di fare?",
  choice_radio_otherTestsNone: "Non ho in previsione nessun altro esame",
  choice_radio_otherTestsHematological: "Esame ematologico",
  choice_radio_otherTestsColonoscopy: "Colonscopia",
  choice_radio_otherTestsEco: "Ecografia",
  choice_radio_otherTestsMore: "Altro",
  choice_radio_yesBecauseChronicDisease: "Sì, perché ho una malattia cronica",
  question_text_screeningprogrambecausechronicdisease: "Quale malattia cronica hai?",
  choice_radio_chronDisease: "Morbo di Chron",
  choice_radio_ulcerativeColotis: "Colite ulcerosa",
  choice_radio_other: "Altro",
  error_get_result: "Errore nel calcolo dei risultati. Per favore, riprova più tardi.",
  error_not_completed: "Completa il questionario prima di inviarlo",
  results_score_label: "Rischio attuale",
  results_level_label_low: "Basso",
  results_level_label_medium: "Medio",
  results_level_label_high: "Alto",
  results_score_10_years_label: "Rischio tra 10 anni",
  results_score_main_factors_label: "Fattori che hanno influito maggiormente sul risultato",
  results_main_factor_ethnicityFactor_title: "Etnia",
  results_main_factor_ethnicityFactor_text: "L’etnia nera presenta un rischio più elevato delle altre; l’etnia bianca è più rischiosa dell’asiatica e ispanica",
  results_main_factor_ageFactor_title: "Età",
  results_main_factor_ageFactor_text: "Con il passare degli anni il nostro organismo diventa sempre più predisposto all'insorgenza di malattie.",
  results_main_factor_bmiFactor_title: "BMI",
  results_main_factor_bmiFactor_text: "L’obesità, ed in generale un elevato BMI, rappresenta un potenziale fattore di rischio",
  results_main_factor_vegetablesFactor_title: "Alimentazione",
  results_main_factor_vegetablesFactor_text: "La quantità di verdura mangiata non risulta essere elevata, e ha incrementato il rischio.",
  results_main_factor_exerciseFactor_title: "Esercizio fisico",
  results_main_factor_exerciseFactor_text: "Il tuo stile di vita risulta piuttosto sedentario.",
  results_main_factor_cigarettesAgeFactor_title: "Fumo",
  results_main_factor_cigarettesAgeFactor_text: "Il fumo ha diversi effetti negativi, che potrebbero essere correlati anche al cancro al colon",
  results_main_factor_numberCigarettesFactor_title: "Fumo",
  results_main_factor_numberCigarettesFactor_text: "Il fumo ha diversi effetti negativi, che potrebbero a lungo andare danneggiare anche la salute",
  results_main_factor_colonscopyFactor_title: "Colonscopia",
  results_main_factor_colonscopyFactor_text: "L’aver effettuato una colonscopia, con esito negativo, riduce decisamente il rischio",
  results_main_factor_nsaidFactor_title: "FANS",
  results_main_factor_nsaidFactor_text: "Assumere regolarmente farmaci anti-infiammatori non steroidei riduce il rischio.",
  results_main_factor_noIbuprofenFactor_title: "Ibuprofene",
  results_main_factor_noIbuprofenFactor_text: "E’ importante sapere se il paziente assume farmaci antinfiammatori non steroidei",
  results_main_factor_estrogenFactor_title: "Estrogeni",
  results_main_factor_estrogenFactor_text: "L'utilizzo di estrogeni incrementa il rischio di cancro al colon.",
  results_main_factor_familyHistoryFactor_title: "Storia familiare",
  results_main_factor_familyHistoryFactor_text: "Il cancro al colon tende ad essere ereditario, dunque l’avere parenti che lo hanno sviluppato incrementa il tuo rischio.",
  results_main_factor_patientSex_title: "Sesso",
  results_main_factor_patientSex_text: "Il genere potrebbe comportare differenze nello sviluppo di un tumore, nella risposta alle terapie e nella mortalità",
  results_toolbar_divider_text: "oppure",
  results_toolbar_send_button_text: "Invia risultati via email",
  results_toolbar_download_button_text: "Scarica PDF",
  results_no_data_to_show: "Si è verificato un problema. Per favore riprova più tardi.",
  questionnaire_step_loading: "Caricamento in corso. Attendere...",
  results_more_info: "Ti invitiamo innanzitutto a condividere i risultati con il tuo medico o specialista",
  results_level_low_more_info: "Ti suggeriamo di fare l’esame del <strong>sangue occulto nelle feci</strong>",
  results_level_medium_more_info_part_1: "Ti suggeriamo di fare l’esame del <strong>sangue occulto nelle feci</strong>",
  results_level_medium_more_info_part_2: "o di effettuare una <strong>colonscopia</strong>",
  results_level_high_more_info: "Ti suggeriamo di effettuare una <strong>colonscopia</strong>",
  results_more_info_text: "se vuoi saperne di più clicca",
  results_more_info_here: "qui",
  results_more_info_details_blood: "Il test del sangue occulto fecale è un esame che viene solitamente utilizzato a scopo di screening per valutare la presenza del cosiddetto sangue occulto nelle feci.<br/>Vengono definite “occulte” tracce di emazie presenti nelle feci ma apparentemente invisibili ad occhio nudo, ad esempio sulla carta igienica piuttosto che sulle pareti del water, dopo che si ha evacuato.<br/>La principale applicazione di questo esame è lo screening per il tumore del colon-retto, a differenza di altre patologie che si manifestano con tracce di sangue piuttosto evidenti, infatti, il carcinoma del colon presenta tipicamente piccole perdite che difficilmente visibili ad occhio nudo. Ciò è dovuto al fatto che a sanguinare non è una lesione voluminosa, ma un piccolo polipo che potenzialmente costituisce l’inizio o l’esito di un processo neoplastico.",
  results_more_info_details_colonoscopy: "La colonscopia è un <strong>esame diagnostico</strong> in grado di visualizzare le pareti interne del colon e di individuare eventuali lesioni, ulcerazioni, occlusioni e masse tumorali. La colonscopia con eventuale uso di <strong>Artificial Intelligence</strong> costituisce il ‘gold standard’ fra le procedure di esplorazione del colon."
};

export default it_questionnaire;

