import React from "react";
import {TypesChoice, TypesQuestionnaireChoicesRadioProps} from "./QuestionnaireChoicesRadio.types";
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {addChoice} from "../../../redux/features/questionnaireChoices/questionnaireChoicesSlice";
import {FormattedMessage} from "react-intl";

const QuestionnaireChoicesRadio = ({
                                     choices,
                                     questionId,
                                     sectionId,
                                     sections,
                                   }: TypesQuestionnaireChoicesRadioProps) => {
  const dispatch = useAppDispatch();
  const savedChoices = useAppSelector((state) => state.questionnaireChoices.choices);

  const selectRadioButton = (e: React.MouseEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>, value: string, id: string) => {
    dispatch(addChoice({
      questionId: questionId,
      choice: [value],
      choiceId: [id],
      sectionId: sectionId,
      type: "radio",
      sections,
    }));
  };

  const sortedChoices = [...choices].sort((c1: TypesChoice, c2: TypesChoice) => {
    if (c1.id > c2.id)
      return 1;
    if (c1.id < c2.id)
      return -1;
    return 0;
  });

  return <div className="questionnairechoicesradio__container" data-question-id={questionId}>
    <ul>
      {sortedChoices.map((choice: TypesChoice, index: number) => {
        return <div
          key={index}
          className="flex items-center"
          onClick={(e: React.MouseEvent<HTMLInputElement>) => {
            selectRadioButton(e, choice.val_value, choice.id.toString());
          }}
        >
          <input
            id={index + "_" + choice.id}
            type="radio"
            value={choice.val_value}
            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
            checked={!!(savedChoices[sectionId] && savedChoices[sectionId][questionId] && savedChoices[sectionId][questionId].choice?.includes(choice.val_value))}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              selectRadioButton(e, choice.val_value, choice.id.toString());
            }}
          />
          <label htmlFor={index + "_" + choice.id} className="ml-3 block text-sm font-medium text-gray-700">
            <FormattedMessage id={"choice_radio_" + choice.des_locale_key}/>
          </label>
        </div>;
      })}
    </ul>
  </div>;
};

export default QuestionnaireChoicesRadio;