import React, {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useAppSelector} from "../../../redux/hooks";
import {TypesQuestionnaireResultsProps} from "./QuestionnaireResults.types";
import QuestionnaireHeader from "../questionnaire-header/QuestionnaireHeader";
import {TypesMostInfluentialParameters} from "../../../pages/Questionnaire/Questionnaire.types";
import {getFactorIcon, getMoreInfoByScore, getMoreInfoDetailsByScore} from "./QuestionnaireResults.lib";
import useResultsDownload from "../../../hooks/use-results-download/useResultsDownload";
import QuestionnaireScoreChart from "../questionnaire-score-chart/QuestionnaireScoreChart";
import QuestionnaireResultsModal from "../questionnaire-results-modal/QuestionnaireResultsModal";
import "./QuestionnaireResults.scss";

const QuestionnaireResults = ({organizationData}: TypesQuestionnaireResultsProps) => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [moreInfoDetailsOpen, setMoreInfoDetailsOpen] = useState("");
  const results = useAppSelector((state) => state.questionnaireResults.results);
  const downloadResults = useResultsDownload();
  const intl = useIntl();

  return <div className="questionnaireresults__container py-4 px-8 max-w-screen-xl m-auto">
    <QuestionnaireHeader organizationData={organizationData}/>
    {results && results.val_score ? <div className="questionnaireresults__body mt-12">
      {/* Score */}
      <div className="questionnaireresults__score flex flex-col md:items-end md:flex-row md:gap-3 mt-4">
        <div className="questionnaireresults__score-label text-3xl font-extrabold text-center md:w-64 md:text-left">
          <FormattedMessage id="results_score_label"/>:
        </div>
        <div className="questionnaireresults__score-chart md:ml-4 md:m-0 m-auto">
          <QuestionnaireScoreChart score={results.val_score} thresholds={results.thresholds}/>
        </div>
      </div>
      {/* Score 10 years*/}
      <div className="questionnaireresults__score-10 flex flex-col md:items-end md:flex-row md:gap-3 mt-8 md:mt-4">
        <div className="questionnaireresults__score-10-label text-xl font-bold text-center md:w-64 md:text-left">
          <FormattedMessage id="results_score_10_years_label"/>:
        </div>
        <div className="questionnaireresults__score-chart md:ml-4 md:m-0 m-auto">
          <QuestionnaireScoreChart score={results.val_score_10yrs} thresholds={results.thresholds}/>
        </div>
      </div>
      {/* More info */}
      <div
        className="questionnaireresults__results-more-info mt-8 bg-blue-700 rounded-xl px-5 py-4 text-white font-normal">
        <div className="questionnaireresults__results-more-info-details-text">
          {getMoreInfoByScore(results.val_score, results.thresholds, intl, moreInfoDetailsOpen, setMoreInfoDetailsOpen)}
        </div>
        {moreInfoDetailsOpen &&
          <div className="questionnaireresults__results-more-info-details mt-6 px-2">
            <i>{getMoreInfoDetailsByScore(results.val_score, results.thresholds, intl, moreInfoDetailsOpen)}</i>
          </div>}
      </div>
      {/* Top parameters */}
      {!results['error_info'] && <div className="questionnaireresults__score-main-factors mt-8">
        <div className="questionnaireresults__score-main-factors-label text-lg">
          <FormattedMessage id="results_score_main_factors_label"/>:
        </div>
        <div
          className="questionnaireresults__score-main-factors grid grid-cols-1 lg:grid-cols-3 gap-6 px-0 md:px-2 mt-5">
          {results['most_influential_parameters'].map((parameterData: TypesMostInfluentialParameters, index: number) =>
            <div
              className="questionnaireresults__score-main-factor bg-yellow-500 rounded-xl flex gap-3 items-center px-3 py-4 text-white"
              key={index}
            >
              <div className="questionnaireresults__score-main-factor-icon">
                {getFactorIcon(parameterData.des_locale_key)}
              </div>
              <div className="questionnaireresults__score-main-factor-content">
                <div className="questionnaireresults__score-main-factor-content-title text-xl font-bold font-bold">
                  <FormattedMessage id={"results_main_factor_" + parameterData.des_locale_key + "_title"}/>
                </div>
                <div className="questionnaireresults__score-main-factor-content-text mt-2 text-base font-bold">
                  <FormattedMessage id={"results_main_factor_" + parameterData.des_locale_key + "_text"}/>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="questionnaireresults__toolbar mt-16">
          {/*<button*/}
          {/*  type="button"*/}
          {/*  className="questionnaireresults__toolbar-send-button py-3 px-8 text-white bg-blue-800 rounded-full flex w-72 gap-4 items-center justify-center"*/}
          {/*  onClick={async () => {*/}
          {/*    setOpen(true);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <div className="questionnaireresults__toolbar-send-button-icon">*/}
          {/*    <span className="material-symbols-outlined">mail</span>*/}
          {/*  </div>*/}
          {/*  <div className="questionnaireresults__toolbar-send-button-text uppercase text-sm font-semibold">*/}
          {/*    <FormattedMessage id={"results_toolbar_send_button_text"}/>*/}
          {/*  </div>*/}
          {/*</button>*/}
          {/*<div className="questionnaireresults__toolbar-divider-text flex items-center">*/}
          {/*  <FormattedMessage id={"results_toolbar_divider_text"}/>*/}
          {/*</div>*/}
          <button
            type="button"
            className="questionnaireresults__toolbar-download-button py-3 px-8 text-white bg-gray-400 rounded-full flex w-72 gap-4 items-center justify-center"
            onClick={async () => {
              await downloadResults(results.id);
            }}
          >
            <div className="questionnaireresults__toolbar-download-button-icon">
              <span className="material-symbols-outlined">download</span>
            </div>
            <div className="questionnaireresults__toolbar-download-button-text uppercase text-lg font-semibold">
              <FormattedMessage id={"results_toolbar_download_button_text"}/>
            </div>
          </button>
        </div>
      </div>}
    </div> : <div className="text-center mt-12 py-6 text-xl"><FormattedMessage id={"results_no_data_to_show"}/></div>}
    <QuestionnaireResultsModal
      open={open}
      setOpen={setOpen}
      email={email}
      setEmail={setEmail}
      resultsId={results ? results.id : null}/>
  </div>;
};

export default QuestionnaireResults;