import {FormattedMessage, useIntl} from "react-intl";
import React from "react";
import {TypesIntroHeaderProps} from "./QuestionnaireHeader.types";
import "./QuestionnaireHeader.scss";
import S3Image from "../../S3Image/s3-image/S3Image";

const QuestionnaireHeader = ({organizationData}: TypesIntroHeaderProps) => {
  const intl = useIntl();

  return <div className="questionnaireheader__container flex flex-col md:flex-row items-center gap-5 justify-between">
    <div className="questionnaireheader__title">
      <div className="questionnaireheader__app-logo-container">
        <img
          className="questionnaireheader__app-logo"
          src={process.env.PUBLIC_URL + "/assets/app-logo.svg"}
          alt={intl.formatMessage({id: "app_title"})}
        />
      </div>
      <div className="questionnaireheader__app-title-container flex flex-wrap content-center">
        <FormattedMessage
          id="app_title"/>
      </div>
    </div>
    <div className="questionnaireheader__company-logo-container">
      {
        organizationData.val_image_url && (
          <S3Image
            src={organizationData.val_image_url}
            alt={organizationData.des_name}
            extraClass="questionnaireheader__company-logo"
          />
        )
      }
    </div>
  </div>;
};

export default QuestionnaireHeader;