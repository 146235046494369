import {TypesIntroProps} from "./Intro.types";
import Button from "../../../ui-components/Button";
import {FormattedMessage, useIntl} from "react-intl";
import React from "react";
import IntroHeader from "../intro-header/IntroHeader";


const Intro = ({setStage, organizationData}: TypesIntroProps) => {
  const intl = useIntl();

  return <div className="intro__container py-4 px-8 max-w-screen-xl m-auto">
    <IntroHeader organizationData={organizationData}/>
    <div className="intro__body">
      <div className="intro__text pt-12 md:pt-20 md:px-20 m-auto">
        <FormattedMessage id="intro_app_welcome_text"/>
      </div>
      <div className="intro__toolbar flex justify-center pt-14">
        <Button
          full={false}
          type="button"
          children={intl.formatMessage({id: "intro_app_intro_button"})}
          onClick={() => {
            setStage(1);
          }}
          className="text-lg"
        />
      </div>
    </div>
  </div>;
};

export default Intro;