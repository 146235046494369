const it_questionnaire_pdf = {
  disclaimer: "Disclaimer",
  disclaimer_text: "Le informazioni derivanti dal questionario compilato, pur avendo una base scientifica, hanno esclusivamente uno scopo informativo.\nI dati risultati dalla compilazione non sostituiscono in alcun modo il parere del medico.\nInvitiamo sempre gli utenti a condividere e discutere indipendentemente dal risultato ottenuto i risultati con il proprio specialista.",
  questionnaire_results: "Risultati del questionario",
  questionnaire_results_subtitle: "In questa sezione sono riportati i risultati del questionario in base alle risposte indicate dall’utente in fase di compilazione. Condividi il risultato con il tuo medico specialista per approfondimenti.",
  answers_summary: "Riepilogo risposte",
  answers_summary_subtitle: "Di seguito sono riportate le principali risposte fornite dall'utente in fase di compilazione che determinano il livello di rischio.",
};

export default it_questionnaire_pdf;

