import {TypesDashboardStatisticsData} from "../../../hooks/use-dashboard-statistics/useDashboardStatistics.types";
import React from "react";
import {FormattedMessage} from "react-intl";


const DashboardStatBlock = ({type, current, delta, um}: TypesDashboardStatisticsData) => {

  const nullPlaceholder = <>&ndash;</>;

  const formatNumber = (v: number | null) => v != null
    ? new Intl.NumberFormat('en-US', {maximumFractionDigits: 2}).format(v)
    : nullPlaceholder;

  const getColor = (v: number | null) => v === 0 || v === null
    ? 'text-gray-400'
    : (
      v < 0
        ? "text-red-700"
        : 'text-green-700'
    );

  return (
    <div className="bg-gray-100 rounded-3xl px-5 py-4 flex-1 border-b-4 border-gray-300">
      <h2 className="text-sm text-gray-500 mb-1">
        <FormattedMessage id={type} />
      </h2>
      <div className="flex items-end justify-between">
        <span className="text-5xl font-extrabold">
          {formatNumber(current)}{um}
        </span>
        <span className={`${getColor(delta)} text-xl font-medium`}>
          {delta !== null && delta >= 0 && '+'}{formatNumber(delta)}{!!delta && um}
        </span>
      </div>
    </div>
  )
}

export default DashboardStatBlock;