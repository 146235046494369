import apiRequest from "@services/apiRequest";
import {useEffect, useState} from "react";
import {TypesApiRequestConfig, TypesQuestionnaireData} from "./useQuestionnaireData.type";

const useQuestionnaireData = () => {
  const [data, setData] = useState<TypesQuestionnaireData>();

  useEffect(() => {
    apiRequest.get("/questionnaire/latest", {publicRequest: true} as TypesApiRequestConfig)
      .then((results) => {
        setData(results as unknown as TypesQuestionnaireData);
      });
  }, []);
  return data;
};

export default useQuestionnaireData;