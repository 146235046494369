import {configureStore} from '@reduxjs/toolkit';
import questionnaireChoicesReducer from "./features/questionnaireChoices/questionnaireChoicesSlice";
import questionnaireInfoReducer from "./features/questionnaireInfo/questionnaireInfoSlice";
import organizationInfoReducer from "./features/organizationInfo/organizationInfoSlice";
import questionnaireResultsReducer from "./features/questionnaireResults/questionnaireResultsSlice";

// Store
export const store = configureStore({
  reducer: {
    questionnaireChoices: questionnaireChoicesReducer,
    questionnaireInfo: questionnaireInfoReducer,
    questionnaireResults: questionnaireResultsReducer,
    organizationInfo: organizationInfoReducer,
  },
});

// Types
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
