import React from "react";
import Spinner from "./Spinner";

const buttonStyles = {
  default: "text-white bg-am-600 hover:bg-am-700",
  white: "text-gray-700 bg-white hover:bg-gray-50",
  secondary: "text-white bg-yellow-500 hover:bg-yellow-600",
  danger: "text-white bg-red-600 hover:bg-red-700",
  blue: "text-white bg-blue-800 hover:bg-blue-900",
};

const Button = ({
  full,
  className = "",
  children,
  styleType = "default",
  submitting = false,
  disabled = false,
  paddingCls = "px-4 py-2",
  textSizeCls = "text-sm",
  ...rest
}) => {
  let defaultClasses =
    `relative overflow-hidden uppercase justify-center inline-flex items-center ${paddingCls} border border-transparent ${textSizeCls} font-bold rounded-full shadow-sm focus:outline-none active:opacity-70 `;

  if (buttonStyles[styleType]) {
    defaultClasses += buttonStyles[styleType];
  } else {
    defaultClasses += buttonStyles.default;
  }

  return (
    <button
      disabled={disabled || submitting}
      className={`${defaultClasses} ${className} ${full ? "w-full" : ""} ${disabled ? "opacity-20 pointer-events-none" : ""}`}
      {...rest}
    >
      {children}
      {submitting ? (
        <Spinner classes={buttonStyles[styleType]} styleType={styleType} />
      ) : null}
    </button>
  );
};

export default Button;
