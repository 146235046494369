import {useEffect, useState} from "react";
import apiRequest from "@services/apiRequest";
import {TypesDashboardAnalyticsPayload} from "./useDashboardScoreAnalytics.types";
import {AnswerFiltersType, DateFiltersType} from "../../pages/Home/index.types";
import {generateParams} from "../../utils/commons";

const useDashboardScoreAnalytics = (
    organizationId: number | null,
    dateFilters: DateFiltersType,
    answerFilters: AnswerFiltersType,
    targets: string[],
) => {
  const [data, setData] = useState<TypesDashboardAnalyticsPayload>();
  useEffect(() => {
    if (targets.length === 0) {
      setData({});
    }
    else {
      setData(undefined);

      const params: any = generateParams(organizationId, dateFilters, answerFilters);
      if (targets.length > 0)
        params.secondary = targets[1];

      apiRequest.get(`/dashboard/analytics/${targets[0]}`, {params})
        .then((results) => {
          setData(results as unknown as TypesDashboardAnalyticsPayload);
        });
    }
  }, [organizationId, dateFilters, answerFilters, JSON.stringify(targets)]);
  return data;
};

export default useDashboardScoreAnalytics;