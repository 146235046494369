import {TypesChoicesData, TypesResponseData} from "./useChoiceDispatcher.style";
import apiRequest from "@services/apiRequest";
import {TypesApiRequestConfig} from "../use-questionnaire-data/useQuestionnaireData.type";
import {TypesOrganizationData} from "../use-organization-data/useOrganizationData.types";
import {TypesChoicesDataSections} from "../../redux/features/questionnaireChoices/questionnaireChoicesSlice.types";

const useChoiceDispatcher = () => {

  return async (savedChoices: TypesChoicesDataSections, questionnaireId: number, organizationData: TypesOrganizationData) => {
    try {
      const response: TypesResponseData = {
        id_organization: organizationData.id,
      };

      response.answers = Object.keys(savedChoices).map((sectionId: string) =>
        Object.keys(savedChoices[sectionId]).map((questionId: string) => {
          const choice: TypesChoicesData = {
            "id_question": parseInt(questionId)
          };
          if (savedChoices[sectionId][questionId].value) {
            choice.val_value = savedChoices[sectionId][questionId].value;
          }
          if (savedChoices[sectionId][questionId].choiceId) {
            choice.choices_ids = savedChoices[sectionId][questionId].choiceId;
          } else {
            choice.choices_ids = [];
          }
          return choice;
        })).flat();
      return await apiRequest.post("/questionnaire/" + questionnaireId, response, {publicRequest: true} as TypesApiRequestConfig);
    } catch (e) {
      console.error("Error getting questionnaire results", e);
      return false;
    }
  };
};

export default useChoiceDispatcher;