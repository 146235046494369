import React from "react";
import SelectRole from "./SelectRole";
import {FormattedMessage} from "react-intl";

export const getColumns = (intl, organizations) => [
  {
    title: "E-mail",
    field: "email",
  },
  {
    title: intl.formatMessage({ id: "name" }),
    field: "full_name",
    render: ({ full_name }) => <b className="whitespace-nowrap">{full_name}</b>,
  },
  {
    title: intl.formatMessage({ id: "role" }),
    field: "role_id",
    customFilterAndSearch: (rolename, rowData) => rowData.role.name.startsWith(rolename),
    render: ({ role }) => <b className="capitalize">{role.name}</b>,
    editComponent: (props) => <SelectRole {...props} />,
    cellStyle: {minWidth: "150px"}
  },
  {
    title: intl.formatMessage({ id: "organization" }),
    field: "id_organization",
    lookup: organizations?.reduce((prev, curr) => ({...prev, [curr.id]: curr.des_name}), {}) ?? {},
    customFilterAndSearch: (search, rowData) => rowData.organization?.des_name.startsWith(search),
    render: ({ organization }) => organization?.des_name ?? <span className="text-gray-400"><FormattedMessage id="noneF" /></span>,
  },
  {
    title: intl.formatMessage({ id: "disabled" }),
    field: "disabled",
    type: "boolean",
  },
];

export const OPTIONS = {
  filtering: true,
  pageSize: 10,
  searchFieldAlignment: "left",
  searchFieldStyle: {
    marginTop: "2px",
  },
  showTitle: false,
  toolbar: false,
};

export const getLocalization = (intl) => ({
  header: {
    actions: "",
  },
  body: {
    editRow: {
      deleteText: intl.formatMessage({ id: "delete_user" }),
    },
  },
  pagination: {
    labelDisplayedRows: intl.formatMessage({ id: "users_displayed_rows" }),
    labelRowsSelect: intl.formatMessage({ id: "users"}),
  },
});
