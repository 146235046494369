import Questionnaire from "@pages/Questionnaire";

const publicRoutes = [
  {
    name: "Questionnarie",
    href: "/survey/:origin?",
    component: Questionnaire
  },
];

export default publicRoutes;
