import React, { useCallback, useContext, useState, useMemo } from "react";
import { useIntl, FormattedMessage } from "react-intl";

// Services
import AuthenticationService from "../../../services/AuthenticationService";
import { NotificationsContext } from "../../Notifications";
import { api } from "@services/apiRequest";
import locale from "../../../locale";

// Ui
import UpdatePassword from "../../forms/UpdatePassword";
import { PageHeading } from "../../Container";
import Select from "../../Select";
import Button from "../../Button";
import Modal from "../../Modal";
import Input from "../../Input";
import Alert from "../../Alert";

function Profile() {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const userData = useMemo(() => AuthenticationService.getUserData() || {}, []);
  const [openPassword, setOpenPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(false);
  const { push } = useContext(NotificationsContext);

  const handleChangePassword = useCallback(
    async (e) => {
      e.preventDefault();

      if (!isValid) {
        return;
      }

      const data = {
        email: userData.email,
        password: password,
        new_password: newPassword,
      };
      try {
        setLoading(() => true);
        setError(() => "");
        const { data: response } = await api.post("/users/password/reset", data);
        if (response.message === "password_updated") {
          push({
            title: intl.formatMessage({ id: "password_updated_successfully" }),
            type: "success",
          });
          setOpenPassword(!openPassword);
        } else {
          setError(() => intl.formatMessage({ id: "form_error" }));
        }
      } catch (error) {
        if (error?.response?.data?.detail === "user_not_found") {
          setError(() => intl.formatMessage({ id: "wrong_old_password" }));
        } else {
          setError(() => intl.formatMessage({ id: "server_error" }));
        }
      } finally {
        setLoading(false);
        setPassword("");
        setNewPassword("");
        setConfirmPassword("");
      }
    },
    [push, newPassword, userData, password, openPassword, intl, isValid]
  );

  function readOnlyField(label, key) {
    return (
      <div className="flex p-4 border-b border-black">
        <div className="w-1/2">
          <b>
            <FormattedMessage id={label} />
          </b>
        </div>
        <div className="w-1/2">{userData[key]}</div>
      </div>
    );
  }

  return (
    <>
      <PageHeading title={intl.formatMessage({ id: "my_profile" })} />

      <div>
        <div aria-label="simple table">
          <div className="border border-black rounded-3xl ">
            {readOnlyField("user_type", "role_name")}
            {readOnlyField("name", "full_name")}
            {readOnlyField("email", "email")}
            <div className="flex  py-2  px-4 items-center ">
              <div className="w-1/2">
                <b>
                  <FormattedMessage id="language" />
                </b>
              </div>
              <div className="w-1/2">
                <Select
                  options={Object.keys(locale).map((l) => ({
                    value: l,
                    name: intl.formatMessage({ id: `language_${l}` }),
                  }))}
                  value={localStorage.getItem("lang")}
                  onChange={(e) => {
                    if (e.target.value !== localStorage.getItem("lang")) {
                      localStorage.setItem("lang", e.target.value);
                      window.location.reload();
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between items-center mt-4">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenPassword(!openPassword)}
        >
          <FormattedMessage id="edit_password" />
        </Button>

        <Button
          styleType="secondary"
          onClick={() => {
            AuthenticationService.logout();
            window.location = "/login";
          }}
        >
          Logout
        </Button>
      </div>
      <Modal
        opened={openPassword}
        onExit={() => setOpenPassword(!openPassword)}
      >
        <div className="m-8">
          <h3 className="text-center text-2xl font-extrabold text-gray-900">
            <FormattedMessage id="edit_password" />
          </h3>
        </div>

        <form onSubmit={handleChangePassword}>
          <Input
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            label="Password"
            type="password"
            errorMessage={intl.formatMessage({
              id: "enter_actual_password",
            })}
            required
          />
          <UpdatePassword
            setPassword={setNewPassword}
            setConfirmPassword={setConfirmPassword}
            password={newPassword}
            confirmPassword={confirmPassword}
            onValid={setIsValid}
          />

          <div className="mt-4 flex items-end justify-end">
            <Button type="submit" submitting={loading} disabled={!isValid}>
              <FormattedMessage id="send" />
            </Button>
          </div>
          {error ? (
            <div className="mt-4">
              <Alert slim type="error" title={error} />
            </div>
          ) : null}
        </form>
      </Modal>
    </>
  );
}

export default Profile;
