import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router";
import {TypesParams} from "./Questionnaire.types";
import Intro from "../../components/QuestionnaireIntro/intro/Intro";
import {useSingleOrganizationData} from "../../hooks/use-organization-data/useOrganizationData";
import {TypesOrganizationData} from "../../hooks/use-organization-data/useOrganizationData.types";
import IntroTermsAndConditions
  from "../../components/QuestionnaireIntro/intro-terms-and-conditions/IntroTermsAndConditions";
import QuestionnaireMain from "../../components/Questionnaire/questionnaire-main/QuestionnaireMain";
import useQuestionnaireData from "../../hooks/use-questionnaire-data/useQuestionnaireData";
import {useAppDispatch} from "../../redux/hooks";
import {addId} from "../../redux/features/questionnaireInfo/questionnaireInfoSlice";
import QuestionnaireResults from "../../components/Questionnaire/questionnaire-results/QuestionnaireResults";
import PoweredBy from "../../ui-components/layout/PoweredBy";
import {TypesQuestionnaireData} from "../../hooks/use-questionnaire-data/useQuestionnaireData.type";
import "./Questionnaire.scss";
import QuestionnaireStepsLoading
  from "../../components/Questionnaire/questionnaire-steps-loading/QuestionnaireStepsLoading";
import {FormattedMessage} from "react-intl";
import { useHistory } from 'react-router-dom';



const DEFAULT_ORIGIN = "olympus";


const Questionnaire = () => {

  const history = useHistory();
  const {origin}: TypesParams = useParams();
  if (!origin)
    history.push(`/survey/${DEFAULT_ORIGIN}`);

  const [stage, setStage] = useState(0);
  const organizationData: TypesOrganizationData | null = useSingleOrganizationData(origin || DEFAULT_ORIGIN);
  const questionnaireData: TypesQuestionnaireData | undefined = useQuestionnaireData();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (questionnaireData) {
      dispatch(addId({questionnaireId: questionnaireData.id,}));
    }
  }, [questionnaireData]);

  const renderContent = useCallback(
    () => {
      switch (stage) {
        case 1:
          return organizationData && <IntroTermsAndConditions
            setStage={setStage}
            organizationData={organizationData}/>;
        case 2:
          return organizationData && questionnaireData ?
            <QuestionnaireMain
              setStage={setStage}
              organizationData={organizationData}
              questionnaireData={questionnaireData}
            /> : <QuestionnaireStepsLoading/>;
        case 3:
          return organizationData && <QuestionnaireResults
            organizationData={organizationData}
          />;
        case 0:
        default:
          return organizationData ? <Intro
            setStage={setStage}
            organizationData={organizationData}
          /> : <div className="text-center my-5">
            <FormattedMessage id="questionnaire_no_organization_data"/>
          </div>;
      }
    },
    [stage, organizationData, questionnaireData],
  );

  return <div
    className="min-w-screen min-h-screen p-0 sm:py-6 sm:px-4 bg-cover bg-no-repeat"
    style={{backgroundImage: `url(${process.env.PUBLIC_URL}/assets/questionnaire-background.png)`}}
  >
    <div
      className="questionnaire__container max-w-6xl mx-auto bg-white bg-opacity-100 sm:bg-opacity-90 sm:min-h-0 min-h-screen sm:rounded-3xl pt-3 pb-4">
      <div className="questionnaire__content">
        {renderContent()}
      </div>
      <div className="questionnaire__footer justify-center mt-4 mb-8">
        <PoweredBy/>
      </div>
    </div>
  </div>;
};

export default Questionnaire;