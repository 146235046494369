import React, {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useOrganizationData} from "../../hooks/use-organization-data/useOrganizationData";
import Select from "../../ui-components/Select";
import useDashboardStatistics from "../../hooks/use-dashboard-statistics/useDashboardStatistics";
import DashboardStatBlock from "../../components/Dashboard/dashboard-stat-block/DashboardStatBlock";
import Placeholder from "../../ui-components/Placeholder";
import {PageSection} from "../../ui-components/Container";
import DashboardQuestionnaireUrl from "../../components/Dashboard/dashboard-questionnaire-url/DashboardQuestionnaireUrl";
import DashboardDemographics from "../../components/Dashboard/dashboard-demographics/DashboardDemographics";
import DashboardScoreAnalytics from "../../components/Dashboard/dashboard-score-analytics/DashboardScoreAnalytics";
import Button from "../../ui-components/Button";
import {TableCellsIcon, FunnelIcon} from "@heroicons/react/24/outline";
import useDashboardDataDownloader from "../../hooks/use-dashboard-data-downloader/useDashboardDataDownloader";
import DashboardLegend from "../../components/Dashboard/dashboard-legend/DashboardLegend";
import {stringToSlug} from "../../utils/commons";
import DatePicker from "../../ui-components/DatePicker";
import {ArrowLongRightIcon} from "@heroicons/react/24/solid";
import Collapsible from "../../ui-components/Collapsible";
import {AnswerFiltersType, DateFiltersType} from "./index.types";
import SearchSelect from "../../ui-components/SearchSelect";
import {FILTER_CHOICES} from "./index.lib";
import useProvincesData from "../../hooks/use-provinces-data/useProvincesData";
import translateChoice from "../../utils/translateChoice";


function Home() {

  const intl = useIntl();

  // state
  const [selectedOrganization, setSelectedOrganization] = useState<number>(-1);
  const [dateFilters, setDateFilters] = useState<DateFiltersType>({
    from_: new Date(new Date().getFullYear(), 0, 1),  // start of year
    to: new Date(),  // today
  });
  const [answerFilters, setAnswerFilter] = useState<AnswerFiltersType>({
    riskRange: [],
    patientSex: [],
    province: [],
    screeningProgram: [],
  });
  const [downloading, setDownloading] = useState<boolean>(false);

  // hooks
  const provinces = useProvincesData();
  const provincesOptions = Object.entries(provinces ?? {})
    .map(([region, innerProvinces]) => ({
      label: region,
      options: innerProvinces
        .sort()
        .map((p) => ({ label: p, value: p })),
    }))
    .sort(({label: a}, {label: b}) => a > b ? 1 : -1);

  // utilities
  const getOrganizationSlugById = (id: number) => {
    if (id < 0)
      return 'olympus';
    const name = organizations?.find((o) => o.id === selectedOrganization)?.des_name;
    return !name ? null : stringToSlug(name);
  }

  // hooks
  const organizations = useOrganizationData();
  const statistics = useDashboardStatistics(selectedOrganization, dateFilters, answerFilters);
  useDashboardDataDownloader(downloading, setDownloading, selectedOrganization, dateFilters, answerFilters);

  return <>
    <div className="flex flex-col md:flex-row items-center justify-between mb-3">
      {/* ----- Header ----- */}
      <h1 className="text-3xl font-extrabold text-am-800 sm:truncate py-6 mt-2">
        <FormattedMessage id="dashboard" defaultMessage="Dashboard" />
      </h1>
      <div className="flex flex-col sm:flex-row items-center gap-3 w-full md:w-auto mt-2">
        {
          organizations
            ? <div className="w-full">
              <Select
                label={intl.formatMessage({id: 'organization'})}
                options={[
                  {name: intl.formatMessage({id: 'all_f'}), value: -1},
                  ...(organizations?.map((o) => ({name: o.des_name, value: o.id})) ?? [])
                ]}
                value={selectedOrganization}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setSelectedOrganization(parseInt(e.target.value)); }}
                customStyles={{width: "100%", minWidth: "15rem"}}
              />
            </div>
            : <div>
              <Placeholder width="w-36" height="h-4" />
              <Placeholder width="w-60" height="h-9" marginCls="" />
            </div>
        }
      </div>
    </div>

    {/* ----- Filters ----- */}
    <div className="my-5 sm:mt-0">
      <div className="bg-gray-100 border-b-4 border-gray-200 px-5 py-4 rounded-3xl">
        <h2 className="flex items-center gap-2 text-lg font-bold mb-3">
          <FunnelIcon className="w-5" />
          <FormattedMessage id="filters" />
        </h2>
        {/* Dates */}
        <div className="sm:text-sm">
          <div className="flex gap-2 items-center mb-4">
            <span className="font-medium mr-3">
              <FormattedMessage id="timespan" />:
            </span>
            <div className="bg-white rounded text-black">
              <DatePicker
                value={dateFilters.from_}
                onChange={(d: Date) => {setDateFilters((prev) => ({...prev, from_: d})); }}
                minDate={null}
                maxDate={new Date()}
              />
            </div>
            <ArrowLongRightIcon className="w-5" />
            <div className="bg-white rounded text-black">
              <DatePicker
              value={dateFilters.to}
              onChange={(d: Date) => {setDateFilters((prev) => ({...prev, to: d})); }}
              minDate={null}
              maxDate={new Date()}
            />
            </div>
          </div>
        </div>
        {/* Other filters */}
        <Collapsible
          title={intl.formatMessage({id: 'show_advanced_filters'})}
          classNames="font-medium text-sm"
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-2 gap-x-4 mt-1">
            {Object.entries(answerFilters).map(([key, value]) =>
              <div key={key}>
                <h3 className="text-xs mb-1">
                  <FormattedMessage id={`demographics_${key}`} />
                </h3>
                {/*// @ts-ignore*/}
                <SearchSelect
                  options={key === 'province'
                    ? provincesOptions
                    // @ts-ignore
                    : FILTER_CHOICES[key].map((o: string) => ({
                      label: translateChoice(intl, key, o, true),
                      value: o
                    }))
                  }
                  // @ts-ignore
                  value={answerFilters[key]}
                  onChange={(selected: {label: string, value: string}[]) => {
                    setAnswerFilter((prev) => ({ ...prev, [key]: selected }));
                  }}
                  isMulti={true}
                  placeholder={<div className="absolute">{intl.formatMessage({id: 'all'})}</div>}
                />
              </div>
            )}
          </div>
        </Collapsible>
      </div>
    </div>


    {/* ----- Statistics ----- */}
    <div className="my-5 sm:mt-0">
      <div className="flex flex-col sm:flex-row justify-items-stretch gap-x-4 gap-y-2">
        {
          statistics
            ?.map((s, idx) => <DashboardStatBlock key={idx} {...s} />)
          ?? Array(3)
            .fill(null)
            .map((_, idx) =>
              <Placeholder
                key={idx}
                classNames="flex-1"
                roundedCls="rounded-3xl"
                height="h-28"
              />
            )
        }
      </div>
      <div className="mt-7">
        <DashboardLegend />
      </div>
    </div>

    {/* ----- Link questionario ----- */}
    <PageSection title={intl.formatMessage({id: "questionnaire_link"})} description={null}>
      <DashboardQuestionnaireUrl
        organization={getOrganizationSlugById(selectedOrganization)}
      />
    </PageSection>

    <PageSection title={intl.formatMessage({id: "summary"})} description={null}>
      {/* ----- Statistiche compilazione questionario ----- */}
      <h3 className="font-medium mb-2 text-lg">
        <FormattedMessage id="demographic_information" />
      </h3>
      <DashboardDemographics
        organization={selectedOrganization > 0 ? selectedOrganization : null}
        dateFilters={dateFilters}
        answerFilters={answerFilters}
      />
      {/* ----- Analisi bivariata su score ----- */}
      <h3 className="font-bold mt-6 mb-3 text-lg">
        <FormattedMessage id="results_explorer" />
      </h3>
      <DashboardScoreAnalytics
        organization={selectedOrganization > 0 ? selectedOrganization : null}
        dateFilters={dateFilters}
        answerFilters={answerFilters}
      />
    </PageSection>

    {/* ----- Download dati ----- */}
    <div className="mb-12 w-full text-center">
      <Button
        styleType="blue"
        full={false}
        textSizeCls="text-lg"
        paddingCls="px-10 py-3"
        onClick={() => { setDownloading(true); }}
        submitting={downloading}
      >
        <TableCellsIcon className="w-7 mr-3" />
        <FormattedMessage id="download_csv_data" />
      </Button>
    </div>
  </>
}

export default Home;
