import it from "./it";
import en from "./en";

if (!localStorage.getItem("lang")) {
  localStorage.setItem("lang", "it");
}

const locale = {
  it,
  en,
};

export const localeMapper = {
  it: "it-IT",
  en: "en-EN"
};

export default locale;
