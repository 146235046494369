import {useEffect, useState} from "react";
import apiRequest from "@services/apiRequest";
import {TypesApiRequestConfig} from "../use-questionnaire-data/useQuestionnaireData.type";

const useS3File = (url: string | null) => {
  const [preSignedUrl, setPreSignedUrl] = useState<string | null>(null);
  useEffect(() => {
    if (!url)
      return;

    const data = {content_type: "", format: "", bucket_path: url, method: "GET"};
    apiRequest.post("/aws-signed-url", data, {publicRequest: true} as TypesApiRequestConfig)
      .then((psu) => {
        setPreSignedUrl(psu as unknown as string);
      })
  }, [url]);
  return preSignedUrl;
}

export default useS3File;