import {FormattedMessage, useIntl} from "react-intl";
import React from "react";
import {TypesIntroHeaderProps} from "./IntroHeader.types";
import "./IntroHeader.scss";
import S3Image from "../../S3Image/s3-image/S3Image";

const IntroHeader = ({organizationData}: TypesIntroHeaderProps) => {
  const intl = useIntl();

  return <div className="introheader__container flex flex-col md:flex-row items-center justify-between">
    <div className="introheader__title text-3xl font-bold flex flex-wrap content-end gap-3">
      <div className="introheader__app-logo-container">
        <img
          className="introheader__app-logo"
          src={process.env.PUBLIC_URL + "/assets/app-logo.svg"}
          alt={intl.formatMessage({id: "app_title"})}
        />
      </div>
      <div className="introheader__app-title-container flex flex-wrap content-center">
        <FormattedMessage
          id="app_title"/>
      </div>
    </div>
    {
      organizationData.val_image_url && (
        <div className="introheader__company-logo-container">
          <S3Image
            src={organizationData.val_image_url}
            alt={organizationData.des_name}
            extraClass="introheader__company-logo"
          />
        </div>
      )
    }
  </div>;
};

export default IntroHeader;