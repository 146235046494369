import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { useIntl, FormattedMessage } from "react-intl";

// Services
import AuthenticationService from "../../../services/AuthenticationService";
import { NotificationsContext } from "../../Notifications";
import { api } from "@services/apiRequest";
import ErrorBoundary from "../../ErrorBoundary";

// Components
import AddUserDialog from "./AddUserDialog";
import UsersTable from "./UsersTable";

// UI
import { UserPlusIcon } from "@heroicons/react/24/solid";
import { PageHeading } from "../../Container";
import Button from "../../Button";
import {useOrganizationData} from "../../../hooks/use-organization-data/useOrganizationData";

function Users() {
  const intl = useIntl();
  const [users, setUsers] = useState();
  const [showNewUserDialog, setShowNewUserDialog] = useState(false);
  const scopes = AuthenticationService.getUserScopes();
  const { push } = useContext(NotificationsContext);
  const organizations = useOrganizationData();

  useEffect(() => {
    const fetchUsers = () => {
      api
        .get('/users')
        .then(({ data: users }) => {
          setUsers(
            (users || []).reduce(
              (usersObj, user) => _.set(usersObj, [user.id], user),
              {}
            )
          );
        })
        .catch(() => {
          setUsers({})
          push({
            title: intl.formatMessage({ id: "server_error" }),
            type: "error",
          });
        });
    };

    if (!users) {
      fetchUsers();
    }
    // eslint-disable-next-line
  }, [users, push]);

  const toggleNewUserDialog = () => setShowNewUserDialog(!showNewUserDialog);
  const canCreateUser = scopes.indexOf("user:create") >= 0;

  if (organizations === null)
    return null;

  return (
    <>
      <PageHeading
        title={intl.formatMessage({ id: "users" })}
        contentActions={
          canCreateUser ? (
            <Button
              variant="contained"
              color="primary"
              onClick={toggleNewUserDialog}
            >
              <UserPlusIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              <FormattedMessage id="add" />
            </Button>
          ) : null
        }
      />

      <ErrorBoundary from="users-table">
        <UsersTable users={users} setUsers={setUsers} push={push} organizations={organizations} />
      </ErrorBoundary>
      {canCreateUser ? (
        <ErrorBoundary from="users-add-dialog">
          <AddUserDialog
            open={showNewUserDialog}
            toggleOpen={toggleNewUserDialog}
            users={users}
            setUsers={setUsers}
            organizations={organizations}
          />
        </ErrorBoundary>
      ) : null}
    </>
  );
}

export default Users;
