import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {addLicense, getPieChart, setChartColor} from "./commons";


function DonutChart({id, data, minHeight="350px", colors=null}) {

  useLayoutEffect(() => {

    addLicense();
    let root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)])
    root.dateFormatter.set("dateFormat", "dd/MM/yyyy")

    // Create chart
    let chart = getPieChart(root)

    // Add series
    let series = chart.series.push(am5percent.PieSeries.new(root, {
      valueField: "value",
      categoryField: "category"
    }))

    // Disabling labels and ticks
    series.labels.template.set("forceHidden", true);
    series.ticks.template.set("forceHidden", true);

    setChartColor(series, colors)

    series.data.setAll(data)

    let legend = chart.children.push(am5.Legend.new(root, {
      centerY: am5.percent(50),
      y: am5.percent(50),
      layout: root.verticalLayout,
      marginRight: 15,
    }));

    legend.data.setAll(series.dataItems);
    legend.valueLabels.template.set("forceHidden", true);

    // Make stuff animate on load
    series.appear(1000)

    return () => {
      root.dispose()
    }
  }, [data, id]) // eslint-disable-line react-hooks/exhaustive-deps

  return <div id={id} style={{ minHeight }}/>
}

export default React.memo(DonutChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
