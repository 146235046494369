import {createSlice} from '@reduxjs/toolkit';
import {TypesChoicesDataState} from "./questionnaireChoicesSlice.types";
import {cleanChoices} from "./questionnaireChoicesSlice.lib";

export const questionnaireChoicesSlice = createSlice({
  name: 'questionnaireChoice',
  initialState: {
    completed: false,
    choices: {},
  } as TypesChoicesDataState,
  reducers: {
    addChoice: (state, action) => {
      if (!state.choices[action.payload.sectionId]) {
        state.choices[action.payload.sectionId] = {};
      }
      state.choices[action.payload.sectionId][action.payload.questionId] = action.payload;
      cleanChoices(action.payload.sections, state.choices);
    },
    removeChoice: (state, action) => {
      if (state.choices[action.payload.sectionId] && state.choices[action.payload.sectionId][action.payload.questionId]) {
        delete state.choices[action.payload.sectionId][action.payload.questionId];
        cleanChoices(action.payload.sections, state.choices);
      }
    },
  }
});


export const {addChoice, removeChoice} = questionnaireChoicesSlice.actions;
export default questionnaireChoicesSlice.reducer;
