import React, {useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import AuthenticationService from "../../../services/AuthenticationService";
import {PageHeading} from "../../../ui-components/Container";
import Button from "../../../ui-components/Button";
import {PlusIcon} from "@heroicons/react/24/solid";
import OrganizationsTable from "../organizations-table/OrganizationsTable";
import OrganizationsAddDialog from "../organizations-add-dialog/OrganizationsAddDialog";
import {useOrganizationData} from "../../../hooks/use-organization-data/useOrganizationData";


const OrganizationsManager = () => {

  const intl = useIntl();
  const scopes = AuthenticationService.getUserScopes();
  const enableCreation = scopes.includes('organization:create');

  // state
  const [isAddDialogOpen, setIsAddDialogOpen] = useState<boolean>(false);
  const [editedRowsCount, setEditedRowsCount] = useState<number>(0);

  // hooks
  const organizationData = useOrganizationData(true, isAddDialogOpen, editedRowsCount);

  return <>
    <PageHeading
        title={intl.formatMessage({ id: "organizations" })}
        contentActions={
          enableCreation ? (
            <Button onClick={() => { setIsAddDialogOpen(true); }} full={false}>
              <PlusIcon
                className="-ml-0.5 mr-2 h-4 w-4"
                aria-hidden="true"
              />
              <FormattedMessage id="add" />
            </Button>
          ) : null
        }
    />

    <OrganizationsTable {...{organizationData, setEditedRowsCount}} />

    {
      enableCreation && isAddDialogOpen && (
        <OrganizationsAddDialog onClose={() => { setIsAddDialogOpen(false); }} />
      )
    }
  </>
}

export default OrganizationsManager;