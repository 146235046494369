const it_questionnaire_intro = {
  intro_app_welcome_text: "Fare prevenzione significa prendersi cura di se stessi! Si può fare in svariati modi, anche con un semplice Test come questo. Condividi il risultato con il tuo medico specialista per salvaguardare la tua salute.",
  intro_app_terms_and_conditions_title: "Termini e condizioni del servizio",
  intro_app_terms_and_conditions_text: "Le informazioni derivanti dal questionario compilato, pur avendo una base scientifica, hanno esclusivamente uno scopo informativo.\nI dati risultati dalla compilazione non sostituiscono in alcun modo il parere del medico.\nInvitiamo sempre gli utenti a condividere e discutere indipendentemente dal risultato ottenuto i risultati con il proprio specialista.",
  intro_app_intro_button: "Inizia il questionario",
  intro_app_terms_and_conditions_button: "Vai al questionario",
  intro_app_terms_and_conditions_checkbox_text: "Ho letto i termini e le condizioni di servizio",
  intro_app_terms_and_conditions_download_button: "Scarica termini e condizioni",
};

export default it_questionnaire_intro;