import {useEffect, useState} from "react";
import apiRequest from "@services/apiRequest";
import {TypesDashboardDemographicsCategory} from "./useDashboardDemographics.types";
import {AnswerFiltersType, DateFiltersType} from "../../pages/Home/index.types";
import {generateParams} from "../../utils/commons";

const useDashboardDemographics = (
    organizationId: number | null,
    target: string,
    dateFilters: DateFiltersType,
    answerFilters: AnswerFiltersType,
) => {
  const [data, setData] = useState<TypesDashboardDemographicsCategory[]>();
  useEffect(() => {
    setData(undefined);

    const params = generateParams(organizationId, dateFilters, answerFilters);
    apiRequest.get(`/dashboard/demographics/${target}`, {params})
      .then((results) => {
        setData(results as unknown as TypesDashboardDemographicsCategory[]);
      });
  }, [target, organizationId, dateFilters, answerFilters]);
  return data;
};

export default useDashboardDemographics;