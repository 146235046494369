const en_questionnaire_intro = {
  intro_app_welcome_text: "Fare prevenzione significa prendersi cura di se stessi! Si può fare in svariati modi, anche con un semplice Test come questo. Condividi il risultato con il tuo medico specialista per salvaguardare la tua salute.",
  intro_app_terms_and_conditions_title: "Terms and conditions",
  intro_app_terms_and_conditions_text: "Le informazioni derivanti dal questionario compilato, pur avendo una base scientifica, hanno esclusivamente uno scopo informativo.\nI dati risultati dalla compilazione non sostituiscono in alcun modo il parere del medico.\nInvitiamo sempre gli utenti a condividere e discutere indipendentemente dal risultato ottenuto i risultati con il proprio specialista.",
  intro_app_intro_button: "Start the survey",
  intro_app_terms_and_conditions_button: "Go to the survey",
  intro_app_terms_and_conditions_checkbox_text: "I have read the terms and conditions of service",
  intro_app_terms_and_conditions_download_button: "Download terms and conditions",
};

export default en_questionnaire_intro;

