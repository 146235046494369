import apiRequest from "@services/apiRequest";
import {TypesS3FileDispatch, TypesS3FileSignedUrlResult} from "./useS3FileDispatcher.types";
import axios from "axios";

const useS3FileDispatcher = () => {

  return async ({file, bucketPath, onUploadProgress}: TypesS3FileDispatch) => {

    // --- get signed url to be used to upload image to S3
    const format = file.name.split('.').pop();
    const data = {
      content_type: file.type,
      format: format,
      bucket_path: bucketPath,
      method: "POST",
    };
    const { url, fields }: TypesS3FileSignedUrlResult = await apiRequest.post(`/aws-signed-url`, data);

    // --- prepare s3 upload request's payload
    const formData = new FormData();
    Object.entries(fields).map(([k, v]) => {
      return formData.append(k, v);
    });
    formData.append("file", file);  // actual file has to be appended last

    // --- upload image to s3
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Cache-Control": "max-age=31536000",
      },
      onUploadProgress: onUploadProgress
    };
    await axios.post(url, formData, options);

    return fields.key;
  }

};

export default useS3FileDispatcher;