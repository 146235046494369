import {TypesQuestionnaireChoicesInputProps} from "./QuestionnaireChoicesInput.types";
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {addChoice, removeChoice} from "../../../redux/features/questionnaireChoices/questionnaireChoicesSlice";

const QuestionnaireChoicesInput = ({
                                     type,
                                     questionId,
                                     sectionId,
                                     sections,
                                   }: TypesQuestionnaireChoicesInputProps) => {
  const dispatch = useAppDispatch();
  const savedChoices = useAppSelector((state) => state.questionnaireChoices.choices);

  return <div className="questionnairechoicesinput" id={questionId}>
    <input
      type={type}
      name={type + "_" + questionId}
      id={type + "_" + questionId}
      min={type === "number" ? 0 : undefined}
      className={"block rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" + (type === 'number' ? " w-24" : " max-w-xs")}
      onChange={(e) => {
        if (e.target.value) {
          dispatch(addChoice(
            {
              questionId: questionId,
              value: e.target.value,
              sectionId: sectionId,
              type: "input-" + type,
              sections: sections
            }));
        } else {
          dispatch(
            removeChoice(
              {
                questionId: questionId,
                value: e.target.value,
                sectionId: sectionId,
                type: "input-" + type,
                sections: sections
              }
            )
          );
        }
      }}
      value={
        savedChoices[sectionId] &&
        savedChoices[sectionId][questionId] ? savedChoices[sectionId][questionId]["value"] : ""
      }
    />
  </div>;
};

export default QuestionnaireChoicesInput;