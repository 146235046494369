const en_questionnaire_pdf = {
  disclaimer: "Disclaimer",
  disclaimer_text: "The information deriving from the completed questionnaire, although having a scientific basis, is for informational purposes only.\nThe data resulting from the compilation does not in any way replace the doctor's opinion.\nWe always invite users to share and discuss regardless of the result obtained the results with your specialist.",
  questionnaire_results: "Questionnaire results",
  questionnaire_results_subtitle: "This section shows the results of the questionnaire based on the answers given by the user during the compilation phase. Share the result with your specialist doctor for further information.",
  answers_summary: "Response summary",
  answers_summary_subtitle: "Below are the main answers provided by the user during the compilation phase which determine the level of risk.",
};

export default en_questionnaire_pdf;

