import React, {useContext} from "react";
import {TypesOrganizationsTableProps} from "./OrganizationsTable.types";
import {useIntl} from "react-intl";
import AuthenticationService from "../../../services/AuthenticationService";
import {TypesOrganizationData} from "../../../hooks/use-organization-data/useOrganizationData.types";
import {NotificationsContext} from "../../../ui-components/Notifications";
import MaterialTable from "@material-table/core";
import {tableIcons} from "../../../ui-components/TableIcons";
import TableStyle from "../../../ui-components/TableStyle";
import {getColumns, getLocalization, OPTIONS} from "./Paramaters";
import _ from "lodash";
import {useOrganizationUpdateDispatcher}
  from "../../../hooks/use-organization-dispatcher/useOrganizationDispatcher";


const OrganizationsTable = ({organizationData, setEditedRowsCount}: TypesOrganizationsTableProps) => {

  const intl = useIntl();
  const { push } = useContext(NotificationsContext);
  const dispatchUpdate = useOrganizationUpdateDispatcher();
  const scopes = AuthenticationService.getUserScopes();
  const columns = getColumns(intl);

  const _onRowUpdate = (newData: TypesOrganizationData, oldData: TypesOrganizationData | undefined) =>
    new Promise(async (resolve, reject) => {
      if (_.isEqual(newData, oldData)) {
        resolve(null);  // do nothing
      }
      else {
        try {
          await dispatchUpdate(newData);
          setEditedRowsCount((prev: number) => prev + 1);  // trigger list refresh
          push({type: 'success', title: intl.formatMessage({id: "organization_updated_successfully"})})
          resolve(null);
        }
        catch {
          push({type: 'success', title: intl.formatMessage({id: "server_error"})});
          reject();
        }
      }
    });

  return (
    <TableStyle>
      <MaterialTable
        columns={columns}
        data={organizationData ?? []}
        icons={tableIcons}
        isLoading={organizationData === null}
        options={OPTIONS}
        localization={getLocalization(intl)}
        editable={{
          ...(scopes.indexOf("organization:update") >= 0
            ? { onRowUpdate: _onRowUpdate }
            : {}),
        }}
      />
    </TableStyle>
  )
}

export default React.memo(OrganizationsTable, (props, nextProps) => {
  // Render component only when users change
  return _.isEqual(props.organizationData, nextProps.organizationData);
});