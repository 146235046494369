const asc = (arr) => arr.sort((a, b) => a - b);

const quantile = (arr, q) => {
    const sorted = asc(arr);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
};
export const q25 = (arr) => quantile(arr, .25);
export const q50 = (arr) => quantile(arr, .50);  // median
export const q75 = (arr) => quantile(arr, .75);
export const mean = (arr) => arr.reduce((prev, curr) => prev + curr, 0) / arr.length;

export const stringToSlug = (str) => str.toLowerCase().replace(/[\W_]+/g, '-');

export const generateParams = (organizationId, dateFilters, answerFilters) => {

    // add dates filters
    const params = {};
    params.from_ = new Date(dateFilters.from_.setHours(20, 0, 0, 0));
    params.to = new Date(dateFilters.to.setHours(23, 59, 59, 999));

    // add organization filter
    if (organizationId > 0)
        params.organization = organizationId;

    // add answers filters
    Object.entries(answerFilters)
        .forEach(([key, values]) => {
            if (values === null)
                return;
            if (key === 'riskRange')
                key = 'risk_range';
            params[key] = values.map((o) => o.value);
        });

    return params
}