import {FormattedMessage, useIntl} from "react-intl";
import React, {useState} from "react";
import {TypesDashboardDemographics} from "./DashboardDemographics.types";
import DonutChart from "../../Charts/DonutChart";
import Select from "../../../ui-components/Select";
import {TARGETS} from "./DashboardDemographics.lib";
import useDashboardDemographics from "../../../hooks/use-dashboard-demographics/useDashboardDemographics";
import Placeholder from "../../../ui-components/Placeholder";
import translateChoice from "../../../utils/translateChoice";
import {RISK_COLORS} from "../../Charts/commons";

const DashboardDemographics = ({ organization, dateFilters, answerFilters }: TypesDashboardDemographics) => {

  // state
  const [target, setTarget] = useState<string>(TARGETS[0]);

  // hooks
  const intl = useIntl();
  const data = useDashboardDemographics(organization, target, dateFilters, answerFilters);

  // utilities
  let plotData = data?.map((d) => ({
    original: d.name,
    category: translateChoice(intl, target, d.name, d.translate),
    value: d.count
  }));

  // custom sort plotData in order to keep the colors right
  if (target === 'riskRange' && !!plotData) {
    plotData = ['low', 'medium', 'high'].reduce(
      (prev: any, curr: string) => {
        const toAdd = plotData!.find((pd) => pd.original === curr);
        return [...prev, ...(!!toAdd ? [toAdd] : [])];
      },
      []
    )
  }

  // @ts-ignore
  return <>
    {/* ----- Target attribute selector ----- */}
    <div className="mb-3">
      <Select
        label={null}
        options={TARGETS.map((t) => ({
          name: intl.formatMessage({id: `demographics_${t}`}),
          value: t
        }))}
        value={target}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setTarget(e.target.value); }}
        customStyles={null}
      />
    </div>
    {/* ----- Plot ----- */}
    {plotData === null || plotData === undefined
      ? <Placeholder height="h-80" />
      : (
       plotData.length > 0
         ? <DonutChart
           id="demographics-plot"
           data={plotData}
           // @ts-ignore
           colors={target === 'riskRange' ? RISK_COLORS : null}
         />
         : <div className="h-80 px-20 flex items-center justify-center">
           <span className="text-xl text-gray-400 font-bold">
             <FormattedMessage id="no_available_data_in_timespan" />
           </span>
         </div>
      )
    }

  </>
}

export default DashboardDemographics;