import {IntlShape} from "react-intl";

export const translateChoice = (
    intl: IntlShape,
    dimension: string,
    value: string | null,
    translate: boolean
) => {
  let mapCategory: (c: string | null, translate: boolean) => string;
  switch (dimension) {
    case 'patientAge':
      mapCategory = (c: string | null) => {
        const ageBase = parseInt(c as string);
        return intl.formatMessage(
          {id: 'age_group_identifier'},
          {start: ageBase, end: ageBase + 9}
        )
      }
      break;
    case 'riskRange':
      mapCategory = (c: string | null) => intl.formatMessage({id: c!})
      break;
    default:
      mapCategory = (c: string | null, translate: boolean) => {
        if (c === null)
          return intl.formatMessage({id: 'unspecified'})
        if (!translate || !isNaN(Number(c)))
          return c;
        const localePrefix = ["", "choice_single_select_", "choice_radio_"].find((p) => `${p}${c}` in intl.messages);
        if (localePrefix !== "" && !localePrefix)
          return c;
        return intl.formatMessage({id: `${localePrefix}${c}`});
      }
  }
  return mapCategory(value, translate);
}

export default translateChoice;