import {ChangeEvent, useCallback} from 'react';
import {TypesChoice, TypesQuestionnaireChoicesSingleSelectProps} from "./QuestionnaireChoicesSingleSelect.types";
import {useIntl} from "react-intl";
import {useAppDispatch, useAppSelector} from '../../../redux/hooks';
import {addChoice, removeChoice} from "../../../redux/features/questionnaireChoices/questionnaireChoicesSlice";
import {TypesChoicesDataChoice} from '../../../redux/features/questionnaireChoices/questionnaireChoicesSlice.types';

const QuestionnaireChoicesSignleSelect = (
  {
    choices,
    questionId,
    sectionId,
    dependencies,
    sections,
  }: TypesQuestionnaireChoicesSingleSelectProps
) => {
  const dispatch = useAppDispatch();
  const savedChoices = useAppSelector((state) => state
    .questionnaireChoices.choices);
  const intl = useIntl();

  /**
   * @function getFilteredAndSortedChoices
   *
   * @return Array<choice> Choices filterd and sorted
   */
  const getFilteredAndSortedChoices = useCallback(() => {
      const sortedChoices: Array<TypesChoice | null> = [...choices].sort((a: TypesChoice, b: TypesChoice) => a.id - b.id);
      if (!dependencies) {
        return sortedChoices;
      }
      const valueToCheck = savedChoices[sectionId]?.[dependencies.question_id];
      if (!valueToCheck) {
        return sortedChoices;
      }
      return filterChoices(sortedChoices, dependencies.operator, valueToCheck);
    },
    [savedChoices, choices],
  );

  /**
   * @function filterChoices
   *
   * @param {Array<TypesChoice | null>} choices
   * @param {string} operator
   * @param {string} valueToCheck
   * @return {(TypesChoice | null)[]}
   */
  const filterChoices = (choices: Array<TypesChoice | null>, operator: string, valueToCheck: TypesChoicesDataChoice) =>
    choices.map((choice: TypesChoice | null) => {
      switch (operator) {
        case "gte":
          const choiceVal = valueToCheck.choice?.[0];
          return choiceVal && Number(choice?.val_value) >= Number(choiceVal) ? choice : null;
        case "is_parent":
          const choiceId = valueToCheck.choiceId?.[0];
          return choiceId && Number(choice?.id_parent) === Number(choiceId) ? choice : null;
        default:
          console.warn("Unsupported operator for question " + questionId + ". All choices will be visible.");
          return choice;
      }
    }).filter((choice: TypesChoice | null) => choice !== null);

  /**
   * @function handleSeelctChange
   *
   * @param {React.ChangeEvent<HTMLSelectElement>} e
   */
  const handleSeelctChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const valueSplitted: Array<string> = e.target.value.split("|#|");
    if (valueSplitted[0] === "no_selection") {
      dispatch(
        removeChoice(
          {
            questionId: questionId,
            choice: [valueSplitted[0]],
            choiceId: [Number(valueSplitted[1])],
            sectionId: sectionId,
            type: "single-select",
            sections: sections
          }
        )
      );
    } else {
      dispatch(
        addChoice(
          {
            questionId: questionId,
            choice: [valueSplitted[0]],
            choiceId: [Number(valueSplitted[1])],
            sectionId: sectionId,
            type: "single-select",
            sections: sections
          }
        )
      );
    }
  };

  /**
   * @function getCurrentValue
   *
   * @return {string}
   */
  const getCurrentValue = useCallback(
    () =>
      (savedChoices[sectionId] && savedChoices[sectionId][questionId]) ?
        savedChoices[sectionId][questionId].choice?.[0] + "|#|" + savedChoices[sectionId][questionId].choiceId?.[0] :
        "no_selection",
    [savedChoices],
  );

  return <div className="questionnairechoicessignleselect__container" id={questionId}>
    <select
      id={questionId}
      className="mt-1 block w-full max-w-xs rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
      onChange={handleSeelctChange}
      value={getCurrentValue()}
    >
      <option value="no_selection">
        {intl.formatMessage({id: "choice_single_select_no_value"})}
      </option>
      {getFilteredAndSortedChoices().map((choice: TypesChoice | null, index: number) => choice &&
        <option
          key={index}
          value={choice.val_value + "|#|" + choice.id}
        >
          {choice.flg_translate && isNaN(Number(choice.des_locale_key)) ?
            intl.formatMessage({id: "choice_single_select_" + choice.des_locale_key}) :
            choice.des_locale_key
          }
        </option>
      )}
    </select>
  </div>;
};

export default QuestionnaireChoicesSignleSelect;