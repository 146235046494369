import {useEffect, useState} from "react";
import apiRequest from "@services/apiRequest";
import {TypesScoreThresholds} from "./useScoreThresholds.types";

const useScoreThresholds = () => {
  const [data, setData] = useState<TypesScoreThresholds>();
  useEffect(() => {
    setData(undefined);

    apiRequest.get("/questionnaire/thresholds")
      .then((results) => {
        setData(results as unknown as TypesScoreThresholds);
      });
  }, []);
  return data;
};

export default useScoreThresholds;