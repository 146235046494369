const it_dashboard = {
  all_f: "Tutte",
  questionnaires_count: "Questionari compilati",
  avg_score_current: "Score medio",
  avg_score_10yrs: "Score predittivo medio",
  questionnaire_link: "Link al questionario",
  download_qr: "Scarica QR",
  copy: "Copia",
  summary: "Riepilogo",
  demographic_information: "Informazioni anagrafiche",
  results_explorer: "Risultati ottenuti",
  questionnaire_link_to_clipboard_success: "Link del questionario copiato negli appunti",
  demographics_patientAge: "Fascia d'età",
  demographics_patientSex: "Sesso",
  demographics_riskRange: "Fascia di rischio",
  demographics_region: "Regione",
  demographics_postalCode: "CAP",
  age_group_identifier: "{start}-{end} anni",
  demographics_province: "Provincia",
  demographics_screeningProgram: "Fa parte di un programma di screening",
  demographics_colonscopyLastTenYears: "Ha fatto di recente una colonscopia",
  demographics_bloodInFaeces: "Ha fatto di recente un esame del sangue occulto nelle feci",
  unspecified: "Non specificato",
  select_variables: "Seleziona variabili",
  period_of_time: "Periodo",
  all_time: "Dall'inizio",
  last_year: "Ultimo anno",
  last_month: "Ultimo mese",
  last_week: "Ultima settimana",
  no_available_data_in_timespan: "Nessun dato disponibile per il periodo selezionato",
  download_csv_data: "Scarica dati CSV",
  statistics: "Statistiche",
  legend_title: "Come posso interpretare queste percentuali?",
  legend_description: "Il modello di previsione del rischio calcola, a fronte di ogni compilazione del questionario, uno score di rischio espresso in termini percentuali. Al fine di favorirne la leggibilità sono state definite, sulla base di informazioni statistiche, le seguenti fasce di rischio",
  low: 'Basso',
  medium: 'Medio',
  high: 'Alto',
  risk_threshold_w_upper_limit: 'Score compreso tra {male_lower} e {male_upper} per gli uomini, o tra {female_lower} e {female_upper} per le donne',
  risk_threshold_wo_upper_limit: 'Score maggiore di {male_lower} per gli uomini, o maggiore di {female_lower} per le donne',
}

export default it_dashboard;


