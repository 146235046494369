import apiRequest from "../../services/apiRequest";
import {TypesApiRequestConfig} from "../use-questionnaire-data/useQuestionnaireData.type";
import {useIntl} from "react-intl";

const useSendResults = (push: any) => {
  const intl = useIntl();

  return async (resultId: number, email: string) => {
    try {
      const result = await apiRequest.post(
        "/questionnaire/results/" + resultId + "/send_pdf",
        {
          "email": email,
          "translations": intl.messages
        },
        {
          publicRequest: true,
          responseType: 'blob',
        } as TypesApiRequestConfig
      );

      push({type: 'success', title: intl.formatMessage({id: 'email_sent_successfully'})});
      return result;
    } catch (e) {
      console.error("Error while send email with questionnaire results", e);
      push({type: 'error', title: intl.formatMessage({id: 'email_sending_error'})});
      return false;
    };
  };
};

export default useSendResults;