import React from "react";
import {TypesS3DocumentProps} from "./S3Document.types";
import useS3File from "../../../hooks/use-s3-file/useS3File";

const S3Document = ({ url, children, placeholder, className="text-black" }: TypesS3DocumentProps) => {

  // hooks
  const preSignedUrl = useS3File(url);

  if (!preSignedUrl)
    return <>{placeholder}</>;

  return <a
    href={preSignedUrl}
    className={className}
  >
    {children}
  </a>
}

export default S3Document;