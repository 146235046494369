import {TypesOrganizationData} from "./useOrganizationData.types";
import {useEffect, useState} from "react";
import apiRequest from "@services/apiRequest";
import {TypesApiRequestConfig} from "../use-questionnaire-data/useQuestionnaireData.type";
import {useAppDispatch} from "../../redux/hooks";
import AuthenticationService from "../../services/AuthenticationService";
import {setInfo} from "../../redux/features/organizationInfo/organizationInfoSlice";

export const useOrganizationData = (includeDisabled: boolean = false, closeAddDialog?: boolean, editsCount?: number) => {
  const userData = AuthenticationService.getUserData();
  const [data, setData] = useState<TypesOrganizationData[] | null>(null);

  useEffect(() => {
    if (closeAddDialog)
      return;

    if (userData?.role_name !== "admin")
      return;  // the following request would return a 401 unauthorized

    apiRequest.get("/organizations/", {params: {include_disabled: includeDisabled}})
      .then(results => {
        setData(results as unknown as TypesOrganizationData[]);
      });

  }, [closeAddDialog, editsCount]);

  return data;
};

export const useSingleOrganizationData = (slug: string) => {
  const [data, setData] = useState<TypesOrganizationData | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const pathParam = slug ? encodeURIComponent(slug) : "";
    try {
      apiRequest.get(`/organizations/${pathParam}`, {publicRequest: true} as TypesApiRequestConfig)
        .then((results: any) => {
          dispatch(setInfo({organizationId: results.id, organizationName: results.des_name}));
          setData(results as unknown as TypesOrganizationData);
        });
    } catch (e) {
      console.error("Unable to get organization data.", e);
    }
  }, []);
  return data;
};