import {createSlice} from '@reduxjs/toolkit';
import {TypesQuestionnaireInfoState} from "./questionnaireInfoSlice.types";

export const questionnaireInfoSlice = createSlice({
  name: 'questionnaireInfo',
  initialState: {
    id: null,
  } as TypesQuestionnaireInfoState,
  reducers: {
    addId: (state, action) => {
      state.id = action.payload.questionnaireId;
    },
  },
});

export const {addId} = questionnaireInfoSlice.actions;
export default questionnaireInfoSlice.reducer;