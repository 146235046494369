
import {useEffect, useState} from "react";
import apiRequest from "@services/apiRequest";
import {TypesProvincesData, TypesProvincesRow} from "./useProvincesData.types";

export const useProvincesData = () => {
  const [data, setData] = useState<TypesProvincesData | null>(null);

  useEffect(() => {
    apiRequest.get("/questionnaire/provinces")
      .then((results: any) => {
        setData(results.reduce(
          (prev: any, curr: TypesProvincesRow) => {
            const region = curr.parent!.des_locale_key;
            if (!(region in prev))
              prev[region] = [];
            prev[region].push(curr.des_locale_key);
            return prev
          },
          {}
        ) as unknown as TypesProvincesData);
      });
  }, []);

  return data;
};

export default useProvincesData;