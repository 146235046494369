import it_faq from "./faq/it_faq";
import it_questionnaire_intro from "./questionnaire-intro/it_questionnaire_intro";
import it_questionnaire from "./questionnaire/it_questionnaire";
import it_questionnaire_pdf from "./questionnaire-pdf/it_questionnaire_pdf";
import it_dashboard from "./dashboard/it_dashboard";
import it_form from "./form/it";

const it = {
  entity_user: "Gestione delle utenze in sistema",
  entity_role: "Gestione dei ruoli di autorizzazione in sistema",
  name_and_surname: "Nome e cognome",
  entity_audit: "Gestione dell'audit",
  language_it: "Italiano",
  language_en: "English",
  language: "Lingua",
  Home: "Home",
  dashboard: "Dashboard",
  disabled_user: "Utente disabilitato",
  enter_name_and_surname: "Inserisci nome e cognome",
  enter_name_or_email: "Inserisci email e nome",
  need_to_select_a_role: "Devi selezionare un ruolo",
  delete_user: "Eliminare l'utenza?",
  delete_organization: "Eliminare l'organizzazione?",
  disabled: "Disabilitato",
  user_updated_successfully: "Utenza aggiornata con successo",
  user_deleted_successfully: "Utenza eliminata con successo",
  user_created_successfully: "Utente creato con successo",
  description: "Descrizione",
  role_name: "Nome ruolo",
  add_role_name: "Inserisci il nome del ruolo",
  add_role: "Aggiungi ruolo",
  add_user: "Aggiungi utente",
  add_organization: "Aggiungi organizzazione",
  deleted_role: "Ruolo eliminato",
  updated: "Aggiornati",
  delete_element: "Eliminare l'elemento?",
  role_already_used: "Ruolo già in uso!",
  role_created_successfully: "Ruolo creato con successo",
  add: "Aggiungi",
  server_error: "Errore del server",
  confirm_password: "Conferma password",
  passwords_not_matching: "Le due password non coincidono",
  password_without_spaces: "La password non deve contenere spazi",
  password_length: "La password deve essere lunga almeno 8 caratteri",
  signup: "Registrati",
  error_while_saving: "Errore durante il salvataggio",
  authentication_failed: "Autenticazione fallita",
  send: "Invia",
  password_policy:
    "Inserire una password lunga almeno 8 caratteri, senza spazi",
  user_not_found: "Utente non trovato",
  wrong_old_password: "Vecchia password errata",
  enter_actual_password: "Inserisci password attuale",
  email: "Email",
  name: "Nome",
  role: "Ruolo",
  user_type: "Tipo utente",
  my_profile: "Il mio profilo",
  access_denied: "Accesso negato",
  password_updated_successfully: "Password modificata con successo",
  submit_password: "Invia password",
  enter_your_email_address:
    "Inserisci l'indirizzo email associato alla tua utenza",
  restore_password: "Recupera password",
  forgot_password: "Password dimenticata?",
  edit_password: "Modifica password",
  show_password: "Mostra password",
  form_error: "Controlla i campi del form",
  enter_email: "Inserisci email",
  missing_sender_mail: "Mail del mittente mancante",
  log_into_your_account: "Entra nel tuo account",
  wrong_email_or_password: "Email o password sbagliati",
  unregistered_email: "Email non registrata",
  forgot_password_msg: "Mail inviata! Se non ricevi alcuna notizia da noi nei prossimi 15 minuti, controlla di aver inserito l'indirizzo e-mail corretto e controlla nella cartella spam.",
  roles: "Ruoli",
  roles_displayed_rows: "{from}-{to} di {count} ruoli",
  users: "Utenti",
  users_displayed_rows: "{from}-{to} di {count} utenti",
  organization: "Organizzazione",
  organizations: "Organizzazioni",
  organization_created_successfully: "Organizzazione creata con successo",
  organization_updated_successfully: "Organizzazione modificata con successo",
  organization_deleted_successfully: "Organizzazione eliminata con successo",
  organizations_displayed_rows: "{from}-{to} di {count} utenti",
  components: "Componenti",
  maps: "Mappe",
  tables: "Tabelle",
  graphs: "Grafici",
  users_settings: "Gestione utenze",
  welcome: "Benvenuto",
  test: "test_it",
  audit: "Audit",
  frontend: "Frontend",
  backend: "Backend",
  file_picker_placeholder: "Seleziona uno o più file",
  success_ticket_sent: "Il ticket è stato aggiunto correttamente, prenderemo in carico la richiesta il prima possibile.",
  hubspot_portal: "Hubspot Customer Portal",
  size_limit_exceeded: "Dimensione massima superata",
  customer_portal_loading_failed: "Errore durante il caricamento del link al portale cliente",
  week_datepicker: "Settimana ",
  week_datepicker_not_selected: "Datepicker settimanale",
  daily_datepicker_not_selected: "Datepicker giornaliero",
  thanks: "Grazie!",
  select_an_option: "Seleziona un'opzione dal menu a tendina.",
  ticket_parameters_not_found: "Parametri di invio ticket non presenti",
  troisi_parameters_not_found: "Parametri di invio mail non presenti",
  error_new_ticket_creation: "Errore imprevisto nella creazione di un nuovo ticket",
  error_ticket_hubspot_management: "É stato creato un nuovo ticket, ma qualcosa è andato storto durante le operazioni effettuate dal nostro portale",
  error_ticket_file_attachment: "É stato creato un nuovo ticket, ma non è possibile allegare il file specificato",
  session_expired: "Sessione scaduta",
  refreshing_session_wait: "Rinnovo della sessione in corso, attendere...",
  contract_expiration_date: "Scadenza contratto",
  image: "Immagine",
  imageSuccessfullyUploaded: "Immagine caricata con successo",
  imageUploadError: "Si è verificato un problema nel caricamento dell'immagine",
  edit: "Modifica",
  maxSize: "Dimensione massima: {maxSizeMB}MB",
  exceededImageMaxSize: "Impossibile caricare un'immagine con dimensione superiore a {maxSizeMB}MB",
  fieldRequiredError: "Campo obbligatorio",
  noneF: "Nessuna",
  email_sent_successfully: "Email inviata con successo",
  email_sending_error: "Impossibile inviare la mail",
  questionnaire_no_organization_data: "Caricamento questionario...",
  filters: "Filtri",
  timespan: "Periodo",
  show_advanced_filters: "Mostra filtri avanzati",
  all: "Tutti",
  terms_and_conditions: "Termini e condizioni",
  no_document: "Nessun documento caricato",
  missing_tec_document: "File di termini e condizioni non presente, impossibile procedere",
  ...it_faq,
  ...it_questionnaire_intro,
  ...it_questionnaire,
  ...it_questionnaire_pdf,
  ...it_dashboard,
  ...it_form,
};

export default it;
