import apiRequest from "@services/apiRequest";
import {TypesOrganizationBaseData, TypesOrganizationData} from "../use-organization-data/useOrganizationData.types";

export const useOrganizationDispatcher = () => {
  return async (data: TypesOrganizationBaseData) => {
    return await apiRequest.post("/organizations/", data);
  };
};

export const useOrganizationUpdateDispatcher = () => {
  return async (data: TypesOrganizationData) => {
    return await apiRequest.put(`/organizations/${data.id}`, data);
  };
};