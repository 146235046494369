import {
  TypesQuestionnaireQuestions,
  TypesQuestionnaireSection
} from "../../../hooks/use-questionnaire-data/useQuestionnaireData.type";
import {isQuestionEnabled} from "../../../components/Questionnaire/questionnaire-lib/main";
import {TypesChoicesDataSections} from "./questionnaireChoicesSlice.types";

/**
 * @function cleanChoices
 *
 * @param {Array<TypesQuestionnaireSection>} sections
 * @param {TypesChoicesDataSections} choices
 * @return {TypesChoicesDataSections}
 */
export const cleanChoices = (sections: Array<TypesQuestionnaireSection>, choices: TypesChoicesDataSections) => {
  sections.forEach((section: TypesQuestionnaireSection) => {
    section.questions.forEach((question: TypesQuestionnaireQuestions) => {
      if (!isQuestionEnabled(choices, question) && choices[question.id_section] && choices[question.id_section][question.id]) {
        delete choices[question.id_section][question.id];
        cleanChoices(sections, choices);
      }
    });
  });
  return choices;
};