export const TARGETS = [
  "patientAge",
  "patientSex",
  "riskRange",
  "region",
  "province",
  "postalCode",
  "screeningProgram",
  "colonscopyLastTenYears",
  "bloodInFaeces",
]