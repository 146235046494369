import {TypesQuestionnaireMainProps} from "./QuestionnaireMain.types";
import QuestionnaireHeader from "../questionnaire-header/QuestionnaireHeader";
import QuestionnaireSteps from "../questionnaire-steps/QuestionnaireSteps";
import QuestionnaireStepsBody from "../questionnaire-steps-body/QuestionnaireStepsBody";
import useChoiceDispatcher from "../../../hooks/use-choice-dispatcher/useChoiceDispatcher";
import {useEffect, useState} from "react";
import {useAppSelector} from "../../../redux/hooks";
import {TypesQuestionnaireSection} from "../../../hooks/use-questionnaire-data/useQuestionnaireData.type";
import {isQuestionEnabled} from "../questionnaire-lib/main";
import {isQuestionDone} from "./QuestionnaireMain.lib";

const QuestionnaireMain = ({setStage, organizationData, questionnaireData}: TypesQuestionnaireMainProps) => {
  const [current, setCurrent] = useState(1);
  const [completed, setCompleted] = useState<Array<number>>([]);
  const [filled, setFilled] = useState<Array<number>>([]);
  const choiceDispatcher = useChoiceDispatcher();
  const savedChoices = useAppSelector((state) => state.questionnaireChoices.choices);

  /**
   * Check for completed sections
   */
  useEffect(() => {
    if (Object.keys(savedChoices).length > 0 && questionnaireData) {
      questionnaireData.sections.forEach((section: TypesQuestionnaireSection) => {
          let sectionCompleted = true;
          let sectionFilled = true;
          let isSectionCompletedAndFilledException = {};

          try {
            section.questions.forEach((question => {
              const questionEnabled = isQuestionEnabled(savedChoices, question);
              const questionDone = isQuestionDone(savedChoices, section.id, question.id);
              if (questionEnabled && !questionDone && question.flg_mandatory) {
                sectionCompleted = false;
              }
              if (questionEnabled && !questionDone) {
                sectionFilled = false;
              }
              if (!sectionCompleted && !sectionFilled) {
                throw isSectionCompletedAndFilledException;
              }
            }));
          } catch (e) {
            if (e !== isSectionCompletedAndFilledException) throw e;
          }
          if (sectionFilled) {
            if (!filled.includes(section.id)) {
              const filledCopy = [...filled];
              filledCopy.push(section.id);
              setFilled(filledCopy);
            }
          } else {
            if (filled.includes(section.id)) {
              const filledCopy = [...filled];
              filledCopy.splice(filledCopy.indexOf(section.id), 1); // 2nd parameter means remove one item only
              setFilled(filledCopy);
            }
          }
          if (sectionCompleted) {
            if (!completed.includes(section.id)) {
              const completedCopy = [...completed];
              completedCopy.push(section.id);
              setCompleted(completedCopy);
            }
          } else {
            if (completed.includes(section.id)) {
              const completedCopy = [...completed];
              completedCopy.splice(completedCopy.indexOf(section.id), 1); // 2nd parameter means remove one item only
              setCompleted(completedCopy);
            }
          }
        }
      );
    }
  }, [savedChoices]);


  return <div className="questionnairemain__container pt-4 px-4 md:px-8 max-w-screen-xl m-auto">
    <QuestionnaireHeader organizationData={organizationData}/>
    <QuestionnaireSteps
      questionnaireData={questionnaireData}
      current={current}
      completed={completed}
      filled={filled}
      setCurrent={setCurrent}
    />
    <QuestionnaireStepsBody
      setCurrent={setCurrent}
      current={current}
      total={questionnaireData.sections.length}
      choiceDispatcher={choiceDispatcher}
      sections={questionnaireData.sections}
      organizationData={organizationData}
      setStage={setStage}
      completed={completed}
    />
  </div>;
};

export default QuestionnaireMain;