import React from "react";

const Label = ({ textCls='text-sm font-medium text-gray-700', children }) => {
  return (
    <label className={`block ${textCls}`}>
      {children}
    </label>
  );
};

export default Label;
