import React from "react";
import {TypesQuestionnaireStepsBodyProps} from "./QuestionnaireStepsBody.types";
import {TypesQuestionnaireQuestions} from "../../../hooks/use-questionnaire-data/useQuestionnaireData.type";
import {FormattedMessage, useIntl} from "react-intl";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import QuestionnaireChoicesRadio from "../questionnaire-choices-radio/QuestionnaireChoicesRadio";
import QuestionnaireChoicesInput from "../questionnaire-choices-input/QuestionnaireChoicesInput";
import QuestionnaireChoicesSignleSelect from "../questionnaire-choices-signle-select/QuestionnaireChoicesSignleSelect";
import {addResults} from "../../../redux/features/questionnaireResults/questionnaireResultsSlice";
import {isQuestionEnabled} from "../questionnaire-lib/main";

const QuestionnaireStepsBody = ({
                                  sections,
                                  setCurrent,
                                  current,
                                  total,
                                  choiceDispatcher,
                                  organizationData,
                                  setStage,
                                  completed
                                }: TypesQuestionnaireStepsBodyProps) => {
  const intl = useIntl();
  const savedChoices = useAppSelector((state) => state.questionnaireChoices.choices);
  const questionnaireId = useAppSelector((state) => state.questionnaireInfo.id);
  const dispatch = useAppDispatch();

  /**
   * Send Choices and handle the response
   */
  const handleChoicesSend = async () => {
    if (completed.length < total) {
      alert(intl.formatMessage({id: "error_not_completed"}));
    } else {
      const results = await choiceDispatcher(savedChoices, questionnaireId, organizationData);
      if (!results) {
        alert(intl.formatMessage({id: "error_get_result"}));
      } else {
        dispatch(addResults({results: results}));
        setStage(3);
      }
    }
  };

  /**
   * @function sortQuestion
   *
   * @param {Array<TypesQuestionnaireQuestions>} questions Questions to sort by val_order property
   * @return {Array<TypesQuestionnaireQuestions>} Question sorted
   */
  const sortQuestion = (questions: Array<TypesQuestionnaireQuestions>) => {
    return [...questions].sort((a: TypesQuestionnaireQuestions, b: TypesQuestionnaireQuestions) => a.val_order - b.val_order);
  };

  /**
   * @function getQuestionChoices
   *
   * @param {TypesQuestionnaireQuestions} question Current question
   * @return {JSX.Element} Choices code
   */
  const getQuestionChoices = (question: TypesQuestionnaireQuestions) => {
    switch (question.type.cod_type) {
      case 'single_choice_radio':
        return <QuestionnaireChoicesRadio
          choices={question.choices}
          questionId={question.id.toString()}
          sectionId={question.id_section.toString()}
          sections={sections}
        />;
      case 'integer':
        return <div className="flex items-center gap-3">
          <QuestionnaireChoicesInput
            type="number"
            questionId={question.id.toString()}
            sectionId={question.id_section.toString()}
            sections={sections}
          />
          {question.des_mu && <span className="text-sm">{question.des_mu}</span>}
        </div>;
      case 'single_choice_select':
        return <QuestionnaireChoicesSignleSelect
          choices={question.choices}
          questionId={question.id.toString()}
          sectionId={question.id_section.toString()}
          dependencies={question.val_choices_dependencies}
          sections={sections}
        />;
      default:
        return <div>UNKNOWN COMPONENT: <strong>{question.type.cod_type}</strong></div>;
    }
  };

  /**
   * @function getQuestionCode
   *
   * @param {TypesQuestionnaireQuestions} question Question data
   * @param {number} index Index of question
   * @return {JSX.Element} Question code
   */
  const getQuestionCode = (question: TypesQuestionnaireQuestions, index: number) => isQuestionEnabled(savedChoices, question) &&
    <li
      className="questionnairestepsbody__question-container text-lg overflow-hidden rounded-md bg-white px-4 md:px-6 pt-3 pb-4 md:py-4 shadow"
      key={index}>
      <div className="questionnairestepsbody__question-text font-bold">
        <FormattedMessage
          id={"question_text_" + question.des_locale_key.toLowerCase()}/>
        {question.flg_mandatory && <span className="text-red-600 ml-2">*</span>}
      </div>
      <div className="questionnairestepsbody__question-choices mt-2">
        {getQuestionChoices(question)}
      </div>
    </li>;


  return <div className="questionnairestepsbody__container mt-5 p-4 bg-gray-200 rounded-xl shadow">
    <div className="questionnairestepsbody__title border-b border-gray-300 pb-5 mt-2 mb-5">
      <h3 className="font-medium leading-6 text-gray-900 text-2xl font-bold"><FormattedMessage
        id={"step_title_" + sections[current - 1].des_locale_key}/></h3>
    </div>
    <ul className="space-y-5">
      {sortQuestion(sections[current - 1].questions).map((question: TypesQuestionnaireQuestions, index: number) =>
        getQuestionCode(question, index))}
    </ul>
    <div className="questionnairestepsbody__title border-t border-gray-300 flex justify-end mt-5 pt-4">
      {current < total ?
        <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={() => {
            setCurrent(current + 1);
            window.scrollTo(0, 0);
          }}
        >
          {intl.formatMessage({id: "step_next_section_button"})}
        </button> :
        <button
          type="button"
          className="inline-flex items-center rounded-md border border-transparent bg-green-600	 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={async () => {
            window.scrollTo(0, 0);
            await handleChoicesSend();
          }}
        >
          {intl.formatMessage({id: "send_choices_button"})}
        </button>}
    </div>
  </div>;
};

export default QuestionnaireStepsBody;