import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {addCursor, getRenderAxisY, addLicense, CHART_COLORS} from "./commons";
import {mean, q25, q50, q75} from "../../utils/commons";

/*  =================== ACCEPTED DATA FORMATS ====================
 *  In these examples X-axis is categorical, but can be date too.
 *  1) Raw data (precomputed=false) ------------------------------
 *      data = {
 *        'series_1': [
 *          {x: 'category_1', y: [43.1, 41.2, 51.6, ...]},
 *          {x: 'category_2', y: [51.8, 71.3, 11.2, ...]},
 *          ...
 *          {x: 'category_N', y: [24.2, 75.1, 72.5, ...]}
 *        ],
 *        'series_2': ...
 *        ...
 *        'series_M': ...
 *      }
 *  The Y-axis values will be automatically aggregated in order
 *  to show its distribution
 *  2) Precomputed data (precomputed=true) -----------------------
 *      data = {
 *        'series_1': [
 *          {x: 'category_1', min: 12.34, q1: 23.45, median: 34.56, q3: 45.67, max: 56.78, mean: 35.46},
 *          {x: 'category_2', min: 12.31, q1: 23.24, median: 35.13, q3: 51.67, max: 96.78, mean: 31.46},
 *          ...
 *          {x: 'category_2', min: 12.12, q1: 23.21, median: 35.11, q3: 51.12, max: 96.72, mean: 31.26},
 *        ],
 *        'series_2': ...
 *        ...
 *        'series_M': ...
 *      }
 *  ============================================================== */

const BoxPlotChart = ({
    id,
    data,
    xAxisLabel,
    xAxisType='category',
    yAxisLabel,
    cellStartLocation=0.2,
    cellEndLocation=0.8,
    precomputed=false,
}) => {

  // utilities
  const amCandlestickValuesMapping = {
    lowValueYField: 'min',
    openValueYField: 'q1',
    valueYField: 'q3',
    highValueYField: 'max',
  }
  const getRenderAxisX = (root, type, grid = true ) => {
    const xRenderer = am5xy.AxisRendererX.new(root, {
      cellStartLocation: cellStartLocation,
      cellEndLocation: cellEndLocation,
      ...(type === 'category' ? {minGridDistance: 20} : {})
    });
    if (!grid)
      xRenderer.grid.template.set("forceHidden", true);
    return xRenderer
  }
  const getAxisX = (root, chart, label, type) => {

    const axisClass = type === 'date' ? am5xy.DateAxis : am5xy.CategoryAxis;

    const xRenderer = getRenderAxisX(root, type, false);
    const axisSettings = {
      renderer: xRenderer,
      maxDeviation: 0.3,
    }
    if (type === 'date')
      axisSettings['baseInterval'] = {timeUnit: "day", count: 1};
    else
      axisSettings['categoryField'] = xAxisLabel;

    const xAxis = chart.xAxes.push(axisClass.new(root, axisSettings))
    if (label)
      xAxis.children.push(am5.Label.new(root, {
        text: `[bold]${label}[/]`,
        x: am5.p50,
        centerX: am5.p50
      }))

    // Enable label wrapping
    xRenderer.labels.template.setAll({
      oversizedBehavior: "wrap",
      textAlign: "center"
    });

    // Set up automatic width calculation using an adapter
    xRenderer.labels.template.adapters.add("width", function(width, target) {
      const x0 = xAxis.getDataItemCoordinateY(xAxis.dataItems[0], "category", 0);
      const x1 = xAxis.getDataItemCoordinateY(xAxis.dataItems[0], "category", 1);
      target.set("maxWidth", x1 - x0)
      return x1 - x0;
    });

    return xAxis;
  }
  const aggregateYValues = (values, prefix='') => ({
    [`${prefix}min`]: Math.min(...values),
    [`${prefix}q1`]: q25(values),
    [`${prefix}median`]: q50(values),
    [`${prefix}q3`]: q75(values),
    [`${prefix}max`]: Math.max(...values),
    [`${prefix}mean`]: mean(values),
  });
  const plotData = Object.entries(data ?? {}).reduce(
    (prev, [seriesName, elements]) => {
      const prefix = `${seriesName}_`;
      elements.forEach(({x, ...rest}) => {
        if (!(x in prev))
          prev[x] = {};
        prev[x] = {
          ...prev[x],
          ...(precomputed
            ? Object.keys(rest).reduce((prev, key) => ({...prev, [prefix+key]: rest[key]}), {})
            : aggregateYValues(rest.y, prefix)
          )
        };
      })
      return prev;
    },
    {}
  );
  const seriesPlotData = Object.entries(plotData).map(([x, values]) => ({[xAxisLabel]: x, ...values }));

  useLayoutEffect(() => {

    addLicense();
    const root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)]);
    root.numberFormatter.set("numberFormat", "#,###.##");

    // Create chart
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
        panX: true,
        panY: true,
        wheelX: "panX",
        wheelY: "zoomX",
        maxTooltipDistance: -1
      })
    );
    addCursor(root, chart);

    // Create legend
    const legend = chart.rightAxesContainer.children.push(
      am5.Legend.new(root, {
        centerY: am5.p50,
        y: am5.p50,
      })
    );

    // Create X axis
    const xAxis = chart.xAxes.push(getAxisX(root, chart, xAxisLabel, xAxisType));
    if (xAxisType === 'category')
      xAxis.data.setAll(seriesPlotData);

    // Create Y axis
    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: getRenderAxisY(root)
    }))
    yAxis.children.push(am5.Label.new(root, {
      text: `[bold]${yAxisLabel}[/]`,
      y: am5.p50,
      centerX: am5.p50,
      rotation: -90
    }))

    // Add series
    const createSeries = (name, color, seriesIndex, seriesCount) => {

      // --- determine candlesticks width and location
      const csWidth = am5.percent(100 / seriesCount * ((cellEndLocation - cellStartLocation) / 2));
      let csLocation = (1 / seriesCount * (seriesIndex + 1) - 1 / seriesCount / 2);
      csLocation = csLocation * (cellEndLocation - cellStartLocation) + cellStartLocation;

      const generateSubfields = (obj) => Object.entries(obj)
        .reduce(
          (prev, [cspName, localName]) => ({...prev, [cspName]: `${name}_${localName}`}),
          {}
        );

      // --- distribution series
      const subFields = generateSubfields(amCandlestickValuesMapping);
      const distributionSeries = chart.series.push(
        am5xy.CandlestickSeries.new(root, {
          name: name,
          fill: color,
          stroke: color,
          xAxis: xAxis,
          yAxis: yAxis,
          [xAxisType === 'date' ? 'valueXField' : 'categoryXField']: xAxisLabel,
          ...subFields
        })
      )
      distributionSeries.columns.template.set("themeTags", []);  // remove value-sensitive automatic coloring

      // --- median series
      const medianSeries = chart.series.push(
        am5xy.StepLineSeries.new(root, {
          name: `sl_${name}`,
          stroke: root.interfaceColors.get("background"),
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: `${name}_median`,
          [xAxisType === 'date' ? 'valueXField' : 'categoryXField']: xAxisLabel,
          stepWidth: csWidth,
          locationX: csLocation,
          noRisers: true
        })
      );
      medianSeries.strokes.template.setAll({strokeWidth: 1.5});

      // --- mean series
      const meanSeries = chart.series.push(am5xy.LineSeries.new(root, {
          name: `ln_${name}`,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: `${name}_mean`,
          [xAxisType === 'date' ? 'valueXField' : 'categoryXField']: xAxisLabel,
      }));
      meanSeries.strokes.template.set("strokeOpacity", 0);
      meanSeries.bullets.push(() => {
        let circle = am5.Circle.new(root, {
          fill: root.interfaceColors.get("background"),
          stroke: color,
          radius: 2.5,
        });
        return am5.Bullet.new(root, {
          sprite: circle,
          locationX: csLocation
        });
      });

      // --- setup tooltip
      const tooltip = distributionSeries.set("tooltip", am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: true,
        autoTextColor: false,
        pointerOrientation: "horizontal"
      }));
      tooltip.get("background").setAll({
        fill: am5.color(0xffffff)
      })

      // tooltip title
      tooltip.label.setAll({
        text: xAxisType === 'date'
          ? "[bold]{valueX.formatDate()}[/]"
          : `[bold]${xAxisLabel}[/]: {categoryX}`,
        fill: am5.color(0x000000)
      });

      // tooltip contents
      const extraSeries = ['median', 'mean'];
      const parentSeriesStrokes = {};
      tooltip.label.adapters.add("text", (text) => {
        const values = Object.entries(generateSubfields({...amCandlestickValuesMapping, median: 'median', mean: 'mean'}))
          .map(([cspName, localName]) => {
            const parentSeries = localName.split('_')[1];

            let seriesValues;
            if (!extraSeries.includes(cspName)) {
              seriesValues = chart.series.values
                .filter((s) => s._settings.name.indexOf('_') < 0)
                .map((s) => {
                  const measure = s._settings.name;
                  parentSeriesStrokes[measure] = s.get("stroke").toString();
                  return `[${parentSeriesStrokes[measure]}]●[/] {${s.get(cspName)}}`
                });
            }
            else {
              seriesValues = chart.series.values
                .filter((s) => {
                  const seriesPrefix = cspName === 'mean' ? 'ln_' : 'sl_';
                  return s._settings.name.startsWith(seriesPrefix);
                })
                .map((s) => {
                  const measure = s._settings.name.split('_')[1];
                  return `[${parentSeriesStrokes[measure]}]●[/] {${s.get('valueYField')}}`
                });
            }
            return `[bold]${_.capitalize(parentSeries)}[/]: ${seriesValues.join(' ')}`;
          })
          .join('\n')

        return text + '\n' + values;
      });

      // make each series visible
      [distributionSeries, medianSeries, meanSeries].forEach((s, idx) => {
        if (xAxisType === 'date')
          s.data.processor = am5.DataProcessor.new(root, {
            numericFields: subFields,
            dateFields: [xAxisLabel],
            dateFormat: "yyyy-MM-dd"
          })
        s.data.setAll(seriesPlotData);
        s.appear();
      })

      // legend for distribution only
      legend.data.push(distributionSeries);

      // hide/show median and mean series when distribution series is hidden/shown
      distributionSeries.on("visible", (visible) => {
        [medianSeries, meanSeries].forEach((s) => visible ? s.show() : s.hide());
      });
    }

    // generate series
    Object.keys(data ?? {}).forEach((name, idx, arr) => {
      createSeries(name, CHART_COLORS[idx % CHART_COLORS.length], idx, arr.length);
    })

    return () => {
      root.dispose()
    }
  }, [data, id]) // eslint-disable-line react-hooks/exhaustive-deps

  return <div id={id} style={{minWidth: "800px", minHeight: "350px"}}/>
}

export default React.memo(BoxPlotChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
