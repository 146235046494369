import {TypesChoicesDataSections} from "../../../redux/features/questionnaireChoices/questionnaireChoicesSlice.types";

export const isQuestionDone = (savedChoices: TypesChoicesDataSections, sectionId: number, questionId: number) => {
  let found = false;
  let isQuestionDoneException = {};
  try {
    Object.keys(savedChoices).forEach((sectionKey: string) => {
        if (sectionKey === sectionId.toString()) {
          Object.keys(savedChoices[sectionKey]).forEach((choiceKey: string) => {
              if (choiceKey === questionId.toString()) {
                found = true;
                throw isQuestionDoneException;
              }
            }
          );
        }
      }
    );
  } catch (e) {
    if (e !== isQuestionDoneException) throw e;
  }
  return found;
};