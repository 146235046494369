import apiRequest from "@services/apiRequest";
import {TypesApiRequestConfig} from "../use-questionnaire-data/useQuestionnaireData.type";
import {useIntl} from "react-intl";

const useResultsDownload = () => {
  const intl = useIntl();

  return async (resultId: number) => {
    try {
      return await apiRequest.post(
        "/questionnaire/results/" + resultId + "/pdf",
        intl.messages,
        {
          publicRequest: true,
          responseType: 'blob',
        } as TypesApiRequestConfig
      ).then((response: any) => {
        const URL = window.URL || window.webkitURL;
        const fileURL = URL.createObjectURL(response);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = "risultati_crc_score.pdf";
        link.click();
      });
    } catch (e) {
      console.error("Error performing download of questionnaire results", e);
      return false;
    }
  };
};

export default useResultsDownload;