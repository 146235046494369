import React from "react";
import {FormattedMessage, IntlShape} from "react-intl";
import {Typethresholds} from "../../../redux/features/questionnaireResults/questionnaireResults.types";

/**
 * @function getMoreInfoByScore
 *
 * @param {string | number | null} score
 * @param {Typethresholds} thresholds
 * @param {IntlShape} intl
 * @param {string} moreInfoDetailsOpen
 * @param {React.Dispatch<React.SetStateAction<string>>} setMoreInfoDetailsOpen
 * @return {JSX.Element | string}
 */
export const getMoreInfoByScore = (
  score: string | number | null,
  thresholds: Typethresholds,
  intl: IntlShape,
  moreInfoDetailsOpen: string,
  setMoreInfoDetailsOpen: React.Dispatch<React.SetStateAction<string>>
) => {
  if (!score || !thresholds.low || !thresholds.high || !thresholds.max) {
    return "";
  }

  switch (true) {
    case (score <= thresholds.low):
      return <div className="questionnaireresults__results-more-info-text">
        <span dangerouslySetInnerHTML={{
          __html: intl.formatMessage({id: "results_more_info"}) + ". " +
            intl.formatMessage({id: "results_level_low_more_info"}) + " (" +
            intl.formatMessage({id: "results_more_info_text"}) + " "
        }}>
        </span>
        <span className="cursor-pointer underline" onClick={() => {
          setMoreInfoDetailsOpen(moreInfoDetailsOpen === "" ? "A" : "");
        }}>
          <FormattedMessage id={"results_more_info_here"}/>
        </span>).
      </div>;
    case (score > thresholds.low && score <= thresholds.high):
      return <div className="questionnaireresults__results-more-info-text">
        <span dangerouslySetInnerHTML={{
          __html: intl.formatMessage({id: "results_more_info"}) + ". " +
            intl.formatMessage({id: "results_level_medium_more_info_part_1"}) + " (" +
            intl.formatMessage({id: "results_more_info_text"}) + " "
        }}
        >
        </span>
        <span className="cursor-pointer underline" onClick={() => {
          setMoreInfoDetailsOpen(moreInfoDetailsOpen === "" || moreInfoDetailsOpen === "B" ? "A" : "");
        }}>
          <FormattedMessage id={"results_more_info_here"}/>
        </span>)
        &nbsp;
        <span dangerouslySetInnerHTML={{
          __html: intl.formatMessage({id: "results_level_medium_more_info_part_2"}) + " (" +
            intl.formatMessage({id: "results_more_info_text"}) + " "
        }}
        >
        </span>
        <span className="cursor-pointer underline" onClick={() => {
          setMoreInfoDetailsOpen(moreInfoDetailsOpen === "" || moreInfoDetailsOpen === "A" ? "B" : "");
        }}>
          <FormattedMessage id={"results_more_info_here"}/>
        </span>).
      </div>;
    case (score > thresholds.high):
      return <div className="questionnaireresults__results-more-info-text">
        <span dangerouslySetInnerHTML={{
          __html: intl.formatMessage({id: "results_more_info"}) + ". " +
            intl.formatMessage({id: "results_level_high_more_info"}) + " (" +
            intl.formatMessage({id: "results_more_info_text"}) + " "
        }}
        >
        </span>
        <span className="cursor-pointer underline" onClick={() => {
          setMoreInfoDetailsOpen(moreInfoDetailsOpen === "" ? "A" : "");
        }}>
          <FormattedMessage id={"results_more_info_here"}/>
        </span>).
      </div>;
    default:
      return "";
  }
};


/**
 * @function getMoreInfoDetailsByScore
 *
 * @param {string | number | null} score
 * @param {Typethresholds} thresholds
 * @param {IntlShape} intl
 * @param {string} moreInfoDetailsOpen
 * @return {string}
 */
export const getMoreInfoDetailsByScore = (score: string | number | null, thresholds: Typethresholds, intl: IntlShape, moreInfoDetailsOpen: string,) => {
  if (!score || !thresholds.low || !thresholds.high || !thresholds.max) {
    return "";
  }

  switch (true) {
    case (score <= thresholds.low && moreInfoDetailsOpen === 'A'):
      return <span
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "results_more_info_details_blood"})}}></span>;
    case (score > thresholds.low && score <= thresholds.high && moreInfoDetailsOpen === 'A'):
      return <span
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "results_more_info_details_blood"})}}></span>;
    case (score > thresholds.low && score <= thresholds.high && moreInfoDetailsOpen === 'B'):
      return <span
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "results_more_info_details_colonoscopy"})}}></span>;
    case (score > thresholds.high && moreInfoDetailsOpen === 'A'):
      return <span
        dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "results_more_info_details_colonoscopy"})}}></span>;
    default:
      return "";
  }
};


export const getFactorIcon = (factorLocalKey: string) => {
  switch (factorLocalKey) {
    case "ethnicityFactor":
      return <span className="material-symbols-outlined ">public</span>;
    case "ageFactor":
      return <span className="material-symbols-outlined ">elder</span>;
    case "bmiFactor":
      return <span className="material-symbols-outlined ">conditions</span>;
    case "colonscopyFactor":
      return <span className="material-symbols-outlined ">stethoscope</span>;
    case "nsaidFactor":
    case "noIbuprofenFactor":
    case "estrogenFactor":
      return <span className="material-symbols-outlined ">pill</span>;
    case "exerciseFactor":
      return <span className="material-symbols-outlined ">directions_run</span>;
    case "cigarettesAgeFactor":
    case "numberCigarettesFactor":
      return <span className="material-symbols-outlined">smoking_rooms</span>;
    case "familyHistoryFactor":
      return <span className="material-symbols-outlined">family_restroom</span>;
    case "vegetablesFactor":
      return <span className="material-symbols-outlined">nutrition</span>;
    case "patientSex":
      return <span className="material-symbols-outlined">person</span>;
    default:
      return <span className="material-symbols-outlined">error</span>;
  }
};

/**
 * @function getRoundedScore
 *
 * @param {number} score
 * @return {number} Score rounded
 */
export const getRoundedScore = (score: number) =>
  Math.round((score + Number.EPSILON) * 10) / 10;