import Button from "../../../ui-components/Button";
import {ClipboardDocumentIcon, QrCodeIcon} from "@heroicons/react/24/outline";
import {FormattedMessage, useIntl} from "react-intl";
import React, {useContext} from "react";
import {NotificationsContext} from "../../../ui-components/Notifications";
import {TypesDashboardQuestionnaireUrl} from "./DashboardQuestionnaireUrl.types";
import QRCode from "qrcode.react";

const DashboardQuestionnaireUrl = ({ organization }: TypesDashboardQuestionnaireUrl) => {

  // context
  const { push } = useContext(NotificationsContext);

  // hooks
  const intl = useIntl();

  // utilities
  const questionnaireLink = !!organization ? `${window.location.origin}/survey/${organization}` : null;
  const questionnaireLinkToClipboard = () => {
    if (!questionnaireLink)
       return;
    navigator.clipboard.writeText(questionnaireLink);
    push({type: "success", title: intl.formatMessage({id: "questionnaire_link_to_clipboard_success"})});
  }
  const questionnaireQRID = "questionnaire-qr";
  const downloadQR = () => {
    const canvas = document.getElementById(questionnaireQRID);
    // @ts-ignore
    const pngUrl = canvas!.toDataURL("image/png").replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qr-code.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    downloadLink.remove();
  };

  return (
    <div className="flex flex-col md:flex-row gap-3">
      <div className="border border-gray-300 rounded-3xl py-2 px-4 text-sm bg-gray-100 flex-1">
        {questionnaireLink}
      </div>
      <div className="flex gap-3">
        <Button
          full
          onClick={questionnaireLinkToClipboard}
          disabled={!questionnaireLink}
        >
          <ClipboardDocumentIcon className="w-4 mr-1.5" />
          <FormattedMessage id="copy" />
        </Button>
        <Button
          full
          onClick={downloadQR}
          styleType="secondary"
          paddingCls="px-10 py-2"
          disabled={!questionnaireLink}
        >
          <QrCodeIcon className="w-4 mr-1.5 flex-shrink-0" />
          <span className="whitespace-nowrap">
            {intl.formatMessage({id: 'download_qr'})}
          </span>
        </Button>
      </div>
      {!!questionnaireLink && <div className="hidden">
        <QRCode
          id={questionnaireQRID}
          value={questionnaireLink}
          size={300}
          level={"H"}
          includeMargin={true}
        />
      </div>}
    </div>
  )
}

export default DashboardQuestionnaireUrl;