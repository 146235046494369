import {TypesQuestionnaireQuestions} from "../../../hooks/use-questionnaire-data/useQuestionnaireData.type";
import {TypesChoicesDataSections} from "../../../redux/features/questionnaireChoices/questionnaireChoicesSlice.types";

/**
 * @function isQuestionEnabled
 *
 * @param {TypesChoicesDataSections} savedChoices
 * @param {TypesQuestionnaireQuestions} question
 * @return {boolean} True if question is enabled, false otherwise
 */
export const isQuestionEnabled = (savedChoices: TypesChoicesDataSections, question: TypesQuestionnaireQuestions | null) => {

  if (!question || !question.val_dependencies) {
    return true;
  }
  if (Object.keys(savedChoices).length === 0) {
    return false;
  }

  let isValid: boolean | undefined = false;
  let isValidException = {};

  switch (question.val_dependencies.operator) {
    case "eq":
      try {
        Object.keys(savedChoices).forEach((sectionKey: string) =>
          Object.keys(savedChoices[sectionKey]).forEach((choiceKey: string) => {
              if (savedChoices[sectionKey][choiceKey].questionId === String(question.val_dependencies?.question_id)) {
                const isIncluded = savedChoices[sectionKey][choiceKey].choice?.includes(question.val_dependencies?.value || "");
                isValid = question.val_dependencies?.negate ? !isIncluded : isIncluded;
                throw isValidException;
              }
            }
          )
        );
      } catch (e) {
        if (e !== isValidException) throw e;
      }
      break;
    case "exists":
      try {
        Object.keys(savedChoices).forEach((sectionKey: string) =>
          Object.keys(savedChoices[sectionKey]).forEach((choiceKey: string) => {
              if (savedChoices[sectionKey][choiceKey].questionId === String(question.val_dependencies?.question_id)) {
                isValid = !!savedChoices[sectionKey][choiceKey];
                throw isValidException;
              }
            }
          )
        );
      } catch (e) {
        if (e !== isValidException) throw e;
      }
      break;
    default:
      console.error("Error checking dependencies: unknown operator");
      break;
  }

  return isValid;
};