import {useEffect, useState} from "react";
import apiRequest from "@services/apiRequest";
import {TypesDashboardStatisticsData} from "./useDashboardStatistics.types";
import {AnswerFiltersType, DateFiltersType} from "../../pages/Home/index.types";
import {generateParams} from "../../utils/commons";

const useDashboardStatistics = (
  organizationId: number,
  dateFilters: DateFiltersType,
  answerFilters: AnswerFiltersType,
) => {
  const [data, setData] = useState<TypesDashboardStatisticsData[] | null>(null);
  useEffect(() => {
    setData(null);
    const params = generateParams(organizationId, dateFilters, answerFilters);
    apiRequest.get("/dashboard/stats", {params})
      .then((results) => {
        setData(results as unknown as TypesDashboardStatisticsData[]);
      });
  }, [organizationId, dateFilters, answerFilters]);
  return data;
};

export default useDashboardStatistics;