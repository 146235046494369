const en_dashboard = {
  all_f: "All",
  questionnaires_count: "Completed questionnaires",
  avg_score_current: "Average score",
  avg_score_10yrs: "Average predictive score",
  questionnaire_link: "Questionnaire link",
  download_qr: "Download QR",
  copy: "Copy",
  summary: "Summary",
  demographic_information: "Demographics",
  results_explorer: "Achieved results",
  questionnaire_link_to_clipboard_success: "Questionnaire link was copied to clipboard",
  demographics_patientAge: "Age group",
  demographics_patientSex: "Sex",
  demographics_riskRange: "Risk range",
  demographics_region: "Region",
  demographics_postalCode: "Postal code",
  age_group_identifier: "{start}-{end} years old",
  demographics_province: "Province",
  demographics_screeningProgram: "It is part of a screening program",
  demographics_colonscopyLastTenYears: "Recently had a colonoscopy",
  demographics_bloodInFaeces: "You recently had a fecal occult blood test",
  analytics_patientSex: "Sex",
  analytics_region: "Region",
  unspecified: "Not specified",
  select_variables: "Select variables",
  period_of_time: "Period of time",
  all_time: "From the beginning",
  last_year: "Last year",
  last_month: "Last month",
  last_week: "Last week",
  no_available_data_in_timespan: "No data available for the selected period",
  download_csv_data: "Download CSV data",
  statistics: "Statistics",
  legend_title: "How can I interpret these percentages?",
  legend_description: "The risk forecasting model calculates a risk score expressed in percentage terms for each completion of the questionnaire. In order to facilitate readability, the following risk ranges have been defined on the basis of statistical information",
  low: 'Basso',
  medium: 'Medio',
  high: 'Alto',
  risk_threshold_w_upper_limit: 'Score between {male_lower} and {male_upper} for male patients, or between {female_lower} and {female_upper} for female patients',
  risk_threshold_wo_upper_limit: 'Score greater than {male_lower} for male patients, or greater than {female_lower} for female patients',
}

export default en_dashboard;