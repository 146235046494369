import {FormattedMessage} from "react-intl";
import useScoreThresholds from "../../../hooks/use-score-thresholds/useScoreThresholds";
import {InformationCircleIcon} from "@heroicons/react/24/outline";
import React from "react";

const DashboardLegend = () => {

  // hooks
  const thresholds = useScoreThresholds();

  // utilities
  const ranges: any = !!thresholds
    ? {
      male: {
        low: [0, thresholds.male.low],
        medium: [thresholds.male.low, thresholds.male.high],
        high: [thresholds.male.high, null],
      },
      female: {
        low: [0, thresholds.female.low],
        medium: [thresholds.female.low, thresholds.female.high],
        high: [thresholds.female.high, null],
      },
    }
    : null;
  const getRiskColor = (key: string) => {
    switch (key) {
      case 'low': return {backgroundColor: 'green'};
      case 'medium': return {backgroundColor: 'orange'};
      case 'high': return {backgroundColor: 'red'};
    }
  }
  const formatPercentage = (p: number | null) => p !== null && (
    <span className="font-medium">{p.toFixed(2)}%</span>
  );

  if (!thresholds)
    return null;

  return <>
    <h2 className="flex items-center gap-2">
      <InformationCircleIcon className="w-5" />
      <span className="text-sm font-bold">
        <FormattedMessage id="legend_title" />
      </span>
    </h2>
    <p className="text-sm my-3">
      <FormattedMessage id="legend_description" />
    </p>
    <ul className="text-sm">
      {['low', 'medium', 'high'].map((thresh, idx) => {
        const values = ['male', 'female'].reduce(
          (prev, curr) => ({
            ...prev,
            [`${curr}_lower`]: formatPercentage(ranges[curr][thresh][0]),
            [`${curr}_upper`]: formatPercentage(ranges[curr][thresh][1]),
          }),
          {}
        );
        return <li key={idx} className="flex items-start gap-2 my-1">
          <div className="p-1.5 rounded-full" style={{marginTop: '.25rem', ...getRiskColor(thresh)}} />
          <div>
            <span className="font-semibold"><FormattedMessage id={thresh} /></span>
            <span className="mx-1.5">&ndash;</span>
            <FormattedMessage
              id={`risk_threshold_${thresh === 'high' ? 'wo' : 'w'}_upper_limit`}
              values={values}
            />
          </div>
        </li>
      })}
    </ul>
  </>
}

export default DashboardLegend;