import { ChartPieIcon } from "@heroicons/react/24/outline";
import Home from "@pages/Home";

const routes = [
  {
    name: "dashboard",
    href: "/home",
    Icon: ChartPieIcon,
    component: Home
  },
];

export default routes;
