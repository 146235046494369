import React, {useEffect} from "react";
import apiRequest from "@services/apiRequest";
import {AnswerFiltersType, DateFiltersType} from "../../pages/Home/index.types";
import {generateParams} from "../../utils/commons";

const useDashboardDataDownloader = (
    downloading: boolean,
    setDownloading: React.Dispatch<React.SetStateAction<boolean>>,
    organizationId: number,
    dateFilters: DateFiltersType,
    answerFilters: AnswerFiltersType,
) => {
  useEffect(() => {

    if (!downloading)
      return;

    const params = generateParams(organizationId, dateFilters, answerFilters);
    apiRequest.get("/dashboard/data", {params, responseType: 'blob'})
      .then((results) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(results as any);

        // create "a" HTML element with href to file & click
        const timestamp = (new Date()).toISOString().replace(/[^0-9]/g, '').slice(0, -3);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute(
          'download',
          `${timestamp}_data.csv`
        );
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .finally(() => { setDownloading(false); });
  }, [downloading]);
};

export default useDashboardDataDownloader;