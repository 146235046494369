import React, {ChangeEvent, useContext, useState} from "react";
import Button from "../../../ui-components/Button";
import {PencilSquareIcon} from "@heroicons/react/24/outline";
import useS3FileDispatcher from "../../../hooks/use-s3-file-dispatcher/useS3FileDispatcher";
import {NotificationsContext} from "../../../ui-components/Notifications";
import {FormattedMessage, useIntl} from "react-intl";
import {TypesS3FileUploaderProps} from "./S3FileUploader.types";


const S3FileUploader = ({onChange, bucketPath, accept, maxImageSizeMB=5, children}: TypesS3FileUploaderProps) => {

  const intl = useIntl();
  const { push } = useContext(NotificationsContext);

  // refs
  const fileUploaderRef = React.createRef<HTMLInputElement>();

  // hooks
  const dispatchS3File = useS3FileDispatcher();

  // state
  const [uploading, setUploading] = useState<boolean>(false);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {

    e.preventDefault();

    if (!e.target.files)
      return;
    const file = e.target.files[0];
    if (file.size > maxImageSizeMB * 1e5)
      push({
        type: 'error',
        title: intl.formatMessage(
          {id: 'exceededImageMaxSize'},
          {maxSize: maxImageSizeMB}
        )
      });

    setUploading(true);
    try {
      const bucketFilepath = await dispatchS3File({file, bucketPath});
      onChange(bucketFilepath);
      // push({type: 'success', title: intl.formatMessage({id: 'imageSuccessfullyUploaded'})});
    }
    catch (err) {
      push({type: 'error', title: intl.formatMessage({id: 'imageUploadError'})});
      throw err;
    }
    finally {
      setUploading(false);
    }
  };

  return <>
    <div className="flex items-center gap-4">
      {children}
      <div className="flex-1">
        <Button
          type="button"
          full={false}
          onClick={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            fileUploaderRef.current?.click();
          }}
          submitting={uploading}
        >
          <PencilSquareIcon className="w-4 mr-2" />
          <FormattedMessage id="edit" />
        </Button>
        <p className="text-xs mt-1 whitespace-nowrap text-gray-500">
          <FormattedMessage id="maxSize" values={{maxSizeMB: maxImageSizeMB}} />
        </p>
      </div>
    </div>
    {/* ----- Hidden file uploader ----- */}
    <input
      type="file"
      accept={accept}
      onChange={handleFileChange}
      className="hidden"
      ref={fileUploaderRef}
    />
  </>
}

export default S3FileUploader;